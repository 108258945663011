#announcement-widget {
    width: 100%;

    .section-title {
        font-size: 20px;
        font-weight: bold;
    }

    .announcement-header {
        width: 100%;

        a {
            color: #0099ba;
            position: relative;
            top: 10px;
            right: 25px;
        }

        .hide-unhide {
            color: #0099ba;
            cursor: pointer;
            font-size: 14pt;
            font-family: "ShellBook", sans-serif;
            /*position: absolute;*/
            padding: 0.8% 0px 0px 68%;
            right: 30px;
            top: 8px;

            &:hover {
                text-decoration: underline;
            }

            .hide {
                right: 100px;
                /*position: absolute;*/
            }
        }
    }

    .announcement-maincontainer {
        border: 1px solid #ddd;
        margin-top: 20px;
    }

    .announcement-container:last-child {
        border: none;
    }

    .no-announcments-message {
        font-family: 'ShellBook', sans-serif;
        font-size: 16px;
        margin: 18% 0 18% 0;
    }

    .announcement-container {
        position: relative;
        border-bottom: 1px solid #ddd;
        padding: 15px;
        font-family: ShellMedium, sans-serif;
        text-align: left;
        width: 95%;
        left: 2%;

        &:hover {
            background-color: #fff;

            h1 {
                color: #0099ba;
                text-decoration: underline;
            }
        }

        h1 {
            font-size: 16px;
            color: #0099ba !important;
            font-family: ShellMedium, sans-serif;

            &:hover {
                cursor: pointer !important;
            }
        }

        .summary {
            margin-top: 10px;
            font-size: 14px;
            font-family: ShellBook, sans-serif !important;
            color: #808285;
        }

        .date {
            font-family: ShellBook, sans-serif !important;
            font-size: 14px;
            color: #808285;
            margin-top: 10px;
        }
    }
}
