#menu-modal-edit {
    .display-homepage-check {
        margin-left: 20px;
        color: #A6A6A6;
        font-family: Arial, Helvetica, sans-serif;
        font-style: italic;
    }

    .important-links-help {
        margin-left: 7px;
        color: #dc3545;
        font-family: Arial, Helvetica, sans-serif;
    }

    .links-wrapper > div {
        color: #007bff;
        cursor: pointer;
        padding: 10px 0px 10px 40px;
        position: relative;
        line-height: 24pt;

        span {
            opacity: 0;
            position: absolute;
            top: 0px !important;
            right: 60px;
            font-size: 14px;
        }

        .card-header-before {
            opacity: 0;
        }
    }

    .card-header-before {
        position: absolute;
        margin-top: 10px;
    }

    .handler-edit-div {
        margin-left: 5%;
        display: inline-flex;
    }

    .handler-edit {
        padding-top: 2%;
    }
    //Hide the open new window for edit description
    label.rdw-link-modal-target-option {
        display: none;
    }

    div#category-wrapper {
        width: 100%;
        position: absolute;
        z-index: 1;
        height: 890px !important;
        transition: 0.6s ease-in-out;
        left: 100%;
        background-color: white;
    }

    div#category-wrapper-link {
        width: 100%;
        position: absolute;
        z-index: 1;
        height: 400px !important;
        transition: 0.6s ease-in-out;
        left: 100%;
        background-color: white;
    }

    div#category-wrapper-main {
        width: 100%;
        position: absolute;
        z-index: 1;
        height: 620px !important;
        transition: 0.6s ease-in-out;
        left: 100%;
        background-color: white;
    }

    div#category-wrapper-link.activeSub {
        left: 0px !important;
    }

    div#category-wrapper-main.activeMain {
        left: 0px !important;
    }

    div#modal-body-edit {
        height: 585px !important;
        transition: all 0.8s ease-in-out;
        overflow: auto;
    }

    div#modal-body-edit.activeLink {
        height: 641px !important;
        transition: all 0.8s ease-in-out;
    }

    div#modal-body-edit.activeSub {
        height: 180px !important;
        transition: all 0.8s ease-in-out;
    }

    div#modal-body-edit.activeMain {
        height: 450px !important;
        transition: all 0.8s ease-in-out;
    }

    div#category-wrapper.active {
        left: 0px !important;
    }

    .links-wrapper div div div div:hover {
        background-color: #f5f5f5;

        span {
            opacity: 1;
        }

        .card-header-before {
            opacity: 1;
        }
    }

    .card-header.active {
        border-bottom: none !important;
    }

    .links-wrapper-before {
        content: " ";
        cursor: move;
        display: table;
        width: 14px;
        height: 8px;
        border-top: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
        top: 19px;
        color: #58595b;
        position: absolute;
        font-size: 14px;
        left: 10px;
    }

    .card-header-before {
        content: " ";
        cursor: move !important;
        display: table;
        width: 14px;
        height: 8px;
        border-top: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
    }

    .dragging {
        border: 1px solid;
        // box-shadow: 2px 2px 2px 2px  #0099ba;
        color: #007bff;
        background-color: #fff;
        font-family: ShellMedium, sans-serif;
    }

    .link-category-edit {
        color: #58595b !important;
        position: relative;
        padding-right: 90px;
    }

    .link-category-edit:hover {
        font-family: ShellMedium, sans-serif !important;
    }

    .edit-sublink {
        padding-left: 4%;
        position: relative;
        padding-right: 90px;
        margin-bottom: 3px;
    }

    .edit-header {
        font-family: shellBook, sans-serif;
        font-size: 1.2rem;
        text-align: left;
        color: #0099ba;
        width: 96%;
    }

    .edit-subcategory {
        position: absolute;
        right: 40px;
        font-size: 14px;
        color: #0099ba !important;
        margin-top: 10px;
    }

    .card-header:after {
        content: "\f078";
        font-family: FontAwesome, sans-serif;
        right: 20px;
        position: absolute;
        top: 14px;
        transition: all 200ms;
        opacity: .5;
    }

    .card-header.active:after {
        transform: rotateZ(-180deg);
    }

    .card-header {
        background-color: transparent;
        position: relative;
        padding-left: 34px;
        cursor: pointer;
    }

    .card {
        border: 0px;
        border-bottom: 1px solid rgba(0,0,0,.125) !important;
        border-radius: 0px;
    }

    .card-body {
        padding: 5px 0px 5px 25px;
    }

    .link-edit-wrapper {
        display: none;
        z-index: 1;
        position: relative;

        input {
            width: 98%;
            margin-bottom: 5px;
            padding: 5px;
            font-size: 14px;
            font-family: ShellBook, sans-serif;
        }
    }

    .before-none:before {
        border: none !important;
        content: " " !important;
    }

    div.exapand-all {
        text-align: right;
        display: block;
        color: #0099ba;
        margin-right: 10px;
    }

    .modal-footer {
        border-top: none !important;
        margin-right: 4%;
    }

    .test {
        position: absolute;
        right: 100px;
    }

    .button-save-menu {
        background-color: #0099ba !important;
    }

    .custom-control-input:checked ~ .custom-control-label::before {
        background-color: #0099ba !important;
        border-color: #0099ba !important;
    }

    .category-wrapper > .form-controls-edit {
        height: 606px !important;
        overflow-x: auto;
        padding-right: 3%;
        margin-bottom: 1em !important;
    }

    .modal-footer-category {
        padding-top: 1% !important;
        margin-top: -1% !important;
    }

    .modal-footer-category-main {
        margin-top: 0% !important;
    }

    .modal-body-main {
        margin-top: 3% !important;
    }

    .modal-footer-category-main > .btn-primary {
        margin-top: 0% !important;
        background-color: #0099ba !important;
        border-color: #0099ba !important;
    }

    .link-category-edit:hover > span {
        opacity: 1 !important;
    }

    .link-edit-category-span {
        right: 6%;
        top: 1px !important;
        color: #0099ba;
    }

    span.link-edit-category-main {
        float: right;
        margin-right: 8%;
        opacity: 0;
    }

    .edit-header:hover > span {
        opacity: 1 !important;
    }

    .label-title {
        margin-bottom: 2% !important;
        color: #007bff !important;
    }

    .modal-body-category-edit.modal-body {
        width: 98% !important;
        padding-left: 8% !important;
    }

    .dropdown-edit {
        display: flex;
        margin-top: 10px;
    }

    .dropdown-edit-2 {
        padding-left: 5%;
        width: 50%;
    }

    .dropdown-edit-1 {
        width: 47%;
    }

    .rdw-editor-toolbar {
        border-bottom: 0px;
        border-color: #ced4da;
    }

    .rdw-editor-main {
        border: 1px solid #ced4da;
        border-top: none;
        position: relative;
        top: -6px;
        padding: 20px;
    }

    #category-wrapper {
        .lds-ring {
            position: absolute;
            top: 45px;
            left: 450px;
            z-index: 999;
            width: 210px;
        }

        .img-txt {
            padding-top: 15px;
        }

        .img-upload-button {
            padding-top: 10px;
            max-width: 50%;
            width: 50%;
        }
    }

    form.form-controls-edit {
        height: 606px !important;
        overflow-x: auto;
        padding-right: 3%;
    }

    .form-controls-edit > .form-group {
        margin-bottom: 1rem !important;
    }

    #category-wrapper-main .form-controls {
        margin-bottom: 2em !important;
    }

    #category-wrapper-main .form-group {
        margin-bottom: 1.5em !important;
    }

    #category-wrapper-link .btn-primary {
        background-color: #0099ba;
        border-color: #0099ba;
    }

    .react-wysiwyg-typescript-editor {
        min-height: 110px !important;
        max-height: 140px;
        padding-top: 0px;
        overflow-y: auto;
    }

    .modal-content p {
        margin-bottom: 0 !important;
    }

    .close:hover {
        color: #58595b !important;
        opacity: .5 !important;
    }

    .rdw-text-align-wrapper {
        display: none;
    }

    .rdw-block-wrapper {
        display: none;
    }

    .rdw-fontsize-wrapper {
        display: none;
    }

    .rdw-colorpicker-wrapper {
        display: none;
    }

    .rdw-inline-wrapper > .rdw-option-wrapper:nth-child(4) {
        display: none;
    }

    .rdw-remove-wrapper {
        display: none;
    }

    .modal-body-links > .form-controls {
        margin-bottom: 2em;
    }

    .blue-background-class {
        background-color: #e6f2ff;
    }

    input:invalid {
        border-color: #dc3545;
        padding-right: calc(1.5em + .75rem);
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
        background-repeat: no-repeat;
        background-position: right calc(.375em + .1875rem) center;
        background-size: calc(.75em + .375rem) calc(.75em + .375rem);
    }

    .info-max {
        font-size: 0.8em;
        color: #495057;
    }
}
