#add-leader-section {
    #add-section-wrapper {
        width: 100%;
        position: absolute;
        z-index: 1;
        height: 400px !important;
        transition: 0.6s ease-in-out;
        left: 100%;
        background-color: white;
    }

    #add-section-wrapper.active {
        left: 0px;
        position: relative;
    }

    .not-active {
        display: none;
        position: relative;
        opacity: 0;
    }

    .add-link-btn {
        position: absolute;
        right: 20px;
        padding: 0px;
    }

    .text-nocontact {
        color: #dc3545;
    }

    #add-section-modal {
        padding: 25px;
        margin-bottom: 50px;
    }

    .custom-checkbox {
        margin: 5px;
    }

    .modal-title {
        margin: 0px;
    }

    .arrow-back-category {
        margin-right: 15px;
    }

    .button-cancel-menu {
        width: 22%;
    }

    .img-add {
        border-radius: 16px;
        display: none;
    }
}
