#add-custom-experts-modal {
    .text-nocontact {
        color: #dc3545;
    }

    .img-add {
        border-radius: 16px;
        display: none;
    }

    .edit-expertName {
        font-family: ShellMedium, sans-serif !important;
    }
}
