#dashboard {
    padding-bottom: 400px;
    padding-top: 20px;

    .react-grid-item.static {
        background: #fff;
    }

    .react-grid-item.react-grid-placeholder {
        background: yellow;
        opacity: 0.2;
        transition-duration: 100ms;
        z-index: 2;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        -o-user-select: none;
        user-select: none;
    }
    
    .react-grid-item:not(.react-grid-placeholder) {
        border:none;
        background: none;
    }

    .react-draggable {
        border:2px solid #e6e674 !important;
    }

    .box {
        // border:1px solid #ccc;
        padding: 0px 10px;
        height:100%;
        overflow-y: auto;
        
        svg {
            font-size: 26px;
            color:#e1631c;
            cursor: pointer;
        }
    }

    .box header {
        display: flex;
        justify-content: space-between;
    }
    
    .box .title {
        font-size: 1.2rem;
        font-weight: bold;
        position: relative;
        top: -7px;
    }

    .box a {
        position: relative;
        top: -6px;
    }

    .custom-webpart-hide{
       display:none !important;
       max-height:0px !important;
    }
    .custom-webpart-active{
        border: 1px solid #ddd !important;
    }
}