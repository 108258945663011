#leadership-tab {
    padding: 40px;

    .editTabsLink {
        position: absolute;
        right: -30px;
        padding-right: 25px;
        top: 8px;
        font-weight: bold;
    }

    .nav-tabs {
        border-bottom: 1px solid #dee2e6;
        width: 96%;

        .nav-link {
            border: none;
            width: auto;
        }
    }

    .nav-tabs .active {
        border: 1px solid transparent;
        border-color: #dee2e6 #dee2e6 #fff;
    }

    .nav-tabs a {
        color: #495057;
        font-weight: bold;
    }

    .tab-content {
        border: none;
    }
}

#edit-tabs {
    .delete-tab-title {
        font-family: shellBold, sans-serif;
        margin-bottom: 10px;
    }

    .hidden-tab {
        color: gray;
    }

    .delete-tab-experts {
        margin-left: 15px;
        height: 150px;
        overflow-y: auto;
    }

    .tabname-validate {
        color: #dc3545;
    }

    #modify-tab-wrapper {
        width: 100%;
        position: absolute;
        z-index: 1;
        min-height: 350px !important;
        transition: 0.6s ease-in-out;
        left: 100%;
        background-color: white;
    }

    #modify-tab-wrapper.active-edit-tab {
        left: 0px;
        position: relative;
    }

    .not-active-tabs {
        display: none;
        position: relative;
        opacity: 0;
    }

    #edit-tab-modal {
        height: 300px;
        padding: 25px;
    }

    .custom-switch {
        padding: 10px 40px;
        margin-bottom: 15px;
    }

    .tab-row {
        td {
            border-bottom: 1px solid #dddddd;
            text-align: left;
            padding: 20px 25px 20px 25px;
        }

        td:first-child {
            width: 15em;
            min-width: 15em;
            max-width: 15em;
            word-break: break-all;
        }
    }

    .edit-link-icon {
        cursor: pointer;
        color: #0099ba;
        margin-right: 25px;
        margin-left: 85px;
    }

    .delete-link-icon {
        cursor: pointer;
        color: #e58271;
    }

    .modal-content {
        width: 100%;
    }

    .button-cancel-menu,
    .btn-danger,
    .btn-primary {
        margin-top: 5px;
        width: 28%;
    }

    .default-text {
        font-family: ShellLight Regular, sans-serif;
        color: grey;
        position: absolute;
    }

    .add-link-btn {
        position: absolute;
        right: 40px;
        top: 6px;
        cursor: pointer;
        font-size: small;
    }

    #delete-tab-conf {
        margin-bottom: 10px;
    }

    .tab-handler-edit {
        display: inline-flex;
        margin-left: 5px;
    }

    .edit-tabs-wrapper-before {
        content: " ";
        cursor: move !important;
        display: table;
        width: 14px;
        height: 8px;
        border-top: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
        margin-top: 28px;
    }
}

#leadership {

    .export-excel {
        float: right;
        margin-right: 50px;
        margin-top: 20px;
    }

    .add-link-btn {
        position: absolute;
        right: -8px;
        cursor: pointer;
        color: #0099ba;
        font-family: shellMedium, sans-serif;
    }

    .leadership-section {
        border-bottom: 1px solid #dee2e6;
        padding: 10px;
    }

    .no-data-message {
        margin-top: 25px;
        margin-left: 20px;
        left: 0px;
        position: absolute;
    }

    .addlinkicon svg {
        height: 30px;
        width: 30px;
    }

    .editlinkicon,
    .deletelinkicon {
        margin-top: -8px;
        color: #0078d7 !important;
    }

    a.dropdown-item {
        font-size: 13pt;
        font-family: shellMedium, sans-serif;
        padding: 10px;
    }

    .dropdown-item {
        color: #0078d7 !important;
    }

    .edit-dots {
        width: 0px;
        display: inline-block;
        position: absolute;
    }

    .edit-dots svg {
        color: #e1631c;
    }

    .edit-dots a svg {
        color: #0078d7 !important;
    }

    .edit-dots a:active,
    .edit-dots a:active svg {
        color: #fff !important;
    }

    .edit-dots .btn-primary {
        bottom: 10px;
    }

    .category-heading {
        font-family: ShellBold, sans-serif;
        text-align: left;
        margin-top: 30px;
    }

    .leader-cards {
        display: flex;
        flex-wrap: wrap;
        min-height: 123px;
        padding: 5px;

        .title {
            margin-top: 5px;
            color: #58595b !important;
            text-align: left;
            padding-left: 100px;
        }

        .title a {
            color: #58595b !important;
        }

        .category {
            margin: 8px 0;
            color: #0099ba;
            padding-left: 100px;
            text-align: left;
            font-family: ShellMediumc, sans-serif;
        }

        .description {
            padding-left: 100px;
            text-align: left;
            padding-right: 10px;
        }

        .category-experts {
            width: 48%;
            border: 1px solid #ccc;
            margin: 10px 20px 10px 0px;
            padding: 20px;
            position: relative;
            box-shadow: 2px 1px 4px 2px rgba(0, 0, 0, 0.1);

            img {
                position: absolute;
                width: 80px;
                height: 80px;
                left: 20px;
                top: 25px;
                border-radius: 100px;
            }
        }

        .category-experts:hover {
            background-color: #f1f1f1;
            cursor: pointer;
            transform: scale(1.05);
            border-radius: 6px;
            box-shadow: 2px 1px 4px 2px rgba(0, 0, 0, 0.1);
        }
    }
}
