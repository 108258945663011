#yammer-widget {
    border: 1px solid rgba(0,0,0,.125);
    margin-top: 20px;
    text-align: left;

    .widget-title {
        display: flex;
        justify-content: space-between;
        font-family: ShellMedium, sans-serif;
        position: relative;
        user-select: none;

        h1 {
            font-size: 20px;
        }

        #moderator-tools {
            span {
                margin-right: 10px;
                color: #0099ba;
                cursor: pointer;

                &:hover {
                    text-decoration: underline;
                }

                svg {
                    margin-top: -3px;
                }
            }

            #dropdown-menu-align-right {
                border: none;
                background-color: transparent;
                position: relative;
                left: 10px;
            }

            svg {
                font-size: 26px;
                color: #e1631c;
            }

            .dropdown-item {
                color: #0099ba !important;
            }

            a.dropdown-item:hover {
                color: #0099ba !important;
            }

            .dropdown-menu.show {
                transform: translate3d(-103px, 5px, 0px) !important;
            }

            .unhide {
                text-align: right;
                color: #0099ba;
                cursor: pointer;
                float: right;
                font-size: 17pt;
                font-family: ShellBook, sans-serif;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    .widget-body1 {
        display: flex;
        justify-content: space-between;
        height: 600px;
        width: 100%;
        font-size: 0px;

        .no-results {
            margin: 100px 0 0 0;
        }
    }


    .view-more {
        margin-top: 15px;
        color: #0099ba;
        cursor: pointer;
        font-size: 14px;
        font-family: Arial, Helvetica, sans-serif;

        &:hover {
            text-decoration: underline;
        }
    }

    .card-loading {
        display: flex;

        .css-jn8o09 {
            margin: 0px 5px 0px 5px;
        }
    }
}

#edit-yammer-modal {
    .modal-title {
        margin-left: 0px !important;
    }

    .invalid-input {
        color: #dc3545;
    }

    .query-control {
        margin-top: 5px !important;
    }

    .modal-footer {
        margin-right: 30px;
    }

    .modal-footer .btn {
        margin-right: 10px;
    }
}
