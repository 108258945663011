.apply-filter-wrapper {

    text-align: right;

    .apply-filter {
        color: #0099ba;
        text-align: right;
        margin-top: 10px;
        text-decoration: underline;
        cursor: pointer;
        display: inline-block; 
        margin-left: 10px;
    }

  
}

.custom-filters {
    .forum-top-refiner {
        label {
            margin-right: 10px;
            margin-top: 12px !important;
        }
    }
    .forum-top-refiner > div {
        width: 180px;
    }
    .forum-top-refiner {
        width: 280px;
        display: flex;
    }
    
}