#edit-leader-section {
    .edit-header {
        font-family: shellMedium, sans-serif;
        font-size: 1.2rem;
        text-align: left;
        width: 80%;
    }

    .card-header:after {
        content: "\f078";
        font-family: FontAwesome, sans-serif;
        right: 20px;
        position: absolute;
        top: 14px;
        transition: all 200ms;
        opacity: 0.5;
    }

    .card-header.active:after {
        transform: rotateZ(-180deg);
    }

    .card-header {
        background-color: transparent;
        position: relative;
        padding-left: 34px;
        cursor: pointer;
    }

    .edit-link-icon {
        color: #0099ba;
        margin-bottom: 8px;
    }

    .card {
        border: 0px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.125) !important;
    }

    .leaders-wrapper > div {
        cursor: pointer;
        position: relative;
        line-height: 20pt;

        span {
            position: absolute;
            right: 45px;
            font-size: 20px;
        }
    }

    .leader-wrapper-before {
        content: " ";
        cursor: move !important;
        display: table;
        width: 14px;
        height: 8px;
        border-top: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
        margin-top: 10px;
    }

    .card-wrapper-before {
        content: " ";
        cursor: move;
        display: table;
        width: 14px;
        height: 8px;
        border-top: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
        top: 19px;
        color: #58595b;
        position: absolute;
        font-size: 14px;
        left: 10px;
    }

    .handler-edit {
        padding-top: 3%;
        display: inline-flex;
    }

    .leader-edit-category-span {
        display: none;
    }

    .card-body {
        margin-top: -10px;
    }

    .card-header.active {
        .leader-edit-category-span {
            display: block;
            right: 14%;
            top: 14px;
            color: #0099ba;
            position: absolute;
            cursor: pointer;
        }
    }

    .handler-edit-div {
        margin-left: 8%;
        width: 80%;
        position: absolute;
    }

    .edit-leader {
        padding: 5px;
    }

    .not-active {
        display: none;
        position: relative;
        opacity: 0;
    }

    #edit-section-wrapper.active {
        left: 0px;
        position: relative;
    }

    #add-section-wrapper.active {
        left: 0px;
        position: relative;
    }

    #edit-leader-wrapper.active {
        left: 0px;
        position: relative;
    }

    #edit-section-wrapper {
        width: 100%;
        position: absolute;
        z-index: 1;
        height: 400px !important;
        transition: 0.6s ease-in-out;
        left: 100%;
        background-color: white;
    }

    #edit-section-modal {
        padding: 25px;
        margin-bottom: 50px;
    }

    #add-section-modal {
        padding: 25px;
        margin-bottom: 50px;
    }

    add-section-wrapper {
        width: 100%;
        position: absolute;
        z-index: 1;
        height: 400px !important;
        transition: 0.6s ease-in-out;
        left: 100%;
        background-color: white;
    }

    .add-link-btn {
        position: absolute;
        right: 20px;
        padding: 0px;
    }

    .invalid-input {
        color: #dc3545;
    }

    .button-cancel-menu {
        width: 24%;
    }

    .arrow-back-category {
        margin-right: 15px;
    }

    .modal-title {
        margin-left: 0px;
    }

    .text-nocontact {
        color: #dc3545;
    }
}
