
#quick-links {
    padding: 0px 10px 5px 10px;

    .add-quick-link {
        color: #0099ba;
        margin-bottom: 5px;
        position: absolute;
        right: 20px;
    }

    .add-quick-link:hover {
        text-decoration: underline;
        cursor: pointer;
    }

    .quick-links-header {
        display: inline-flex;
        margin-top: 7px;
        margin-bottom: 5px;
    }

    .add-link-icon {
        margin-bottom: 4px;
    }

    .add-link-disabled {
        color: #6c757d !important;
        pointer-events: none;
        margin-bottom: 5px;
        position: absolute;
        right: 20px !important;
    }

    .edit-link-icon {
        cursor: pointer !important;
        color: #a7a9ac !important;
        margin-left: 100px;
    }

    .delete-link-icon {
        cursor: pointer !important;
        color: #e58271 !important;
    }

    .add-icon {
        margin-bottom: 4px;
    }
}
.modal-title{
    margin-left: 5px !important
}

#edit-main-menu {
    span.topmenu-default-menu {
        font-family: ShellBold, sans-serif;
        color: #212529;
        margin-left: 10px;
    }

    .handler-edit {
        display: inline-flex;
        margin-left: 5px;
    }

    .edit-menu-wrapper-before {
        content: " ";
        cursor: move !important;
        display: table;
        width: 14px;
        height: 8px;
        border-top: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
        margin-top: 20px;
    }

    .delete-tab-title {
        font-family: shellBold, sans-serif;
        margin-bottom: 10px;
    }

    .hidden-tab {
        color: gray;
    }

    .delete-tab-experts {
        margin-left: 15px;
        height: 150px;
        overflow-y: auto;
    }

    .tabname-validate {
        color: #dc3545;
    }

    #modify-tab-wrapper {
        width: 100%;
        position: absolute;
        z-index: 1;
        min-height: 350px !important;
        transition: 0.6s ease-in-out;
        left: 100%;
        background-color: white;
    }

    #modify-tab-wrapper.active-edit-tab {
        left: 0px;
        position: relative;
    }

    .not-active-tabs {
        display: none;
        position: relative;
        opacity: 0;
    }

    #edit-tab-modal {
        height: 300px;
        padding: 25px;
    }

    .tab-row {
        td {
            border-bottom: 1px solid #dddddd;
            text-align: left;
            padding: 15px 5px 15px 25px;
        }

        td:first-child {
            width: 15em;
            min-width: 15em;
            max-width: 15em;
            word-break: break-all;
            font-family: ShellBook, sans-serif;
        }

        .hidden-menu-item {
            color: gray !important;
        }
    }


    .edit-link-icon-topmenu {
        cursor: pointer !important;
        color: #a7a9ac !important;
        margin-left: 100px;
    }

    .delete-link-icon-topmenu {
        cursor: pointer !important;
        color: #e58271 !important;
    }

    .modal-content {
        width: 100%;
    }

    .button-cancel-menu,
    .btn-danger,
    .btn-primary {
        margin-top: 5px;
        width: 28%;
    }



    .add-link-btn {
        position: absolute;
        right: 40px;
        top: 6px;
        cursor: pointer;
        font-size: small;
    }

    #delete-tab-conf {
        margin-bottom: 10px;
    }
}

#edit-menu-item{
.custom-switch {
    padding: 10px 40px;
    margin-bottom: 15px;
}
}
