#custom-webpart {
    .rdw-embedded-wrapper {
        display: none;
    }

    .rdw-fontsize-wrapper {
        display: none;
    }

    .rdw-fontfamily-wrapper {
        display: none;
    }

    .rdw-link-modal {
        left: -140px !important;
    }

    .rdw-image-alignment-options-popup {
        display: none !important;
    }

    .rdw-colorpicker-wrapper {
        display: none;
    }

    .modal-title {
        margin-left: 3px !important;
    }

    .form-label {
        margin-bottom: 6px;
    }

    .wrapper-class {
        padding: 1rem;
        border: 1px solid #ccc;
    }

    .editor-class {
        padding: 0px 12px;
        border: 1px solid #ccc;
        min-height: 200px;
    }

    .toolbar-class {
        border: 1px solid #ccc;
    }

    .webpart-selection {
        color: #e58271 !important;
        font-family: 'ShellBold', sans-serif;
        padding: 0px 20% 1% 0px;
    }

    .error-new {
        float: left;
        margin-top: -3%;
        color: #dc3545;
        font-size: 0.9em;
    }


    .lds-ring {
        position: absolute;
        top: 20px;
        left: 470px;
        z-index: 999;
        width: 230px;
    }

    span.rdw-image-imagewrapper img {
        width: 700px;
    }
}

#delete-events-dialog {
    .delete-title {
        font-weight: bold;
    }
}
