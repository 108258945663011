#about-us-and-contact {
    .modal-content {
        a {
            color: #0099ba;
        }

        background-color: #fff;

        .edit-link {
            position: absolute;
            right: 50px;
            top: 10px;
            z-index: 9;
        }

        .modal-footer.about-footer {
            justify-content: center;
        }

        .modal-footer {
            margin-top: 20px;
        }

        .btn {
            margin-bottom: 10px;
            height: 35px;
            width: 100px;
            padding: 2px;
        }

        .modal-body {
            padding: 25px 50px 20px 50px;
        }

        .about-section {
            display: inline-flex;
        }

        .about-text {
            margin-top: 6px;
        }

        .contact-section {
            display: inline-flex;
            padding-top: 15px;
            border-top: 1px solid #dee2e6;
            margin-top: 15px;
        }

        .contact-section span p {
            margin-left: 20px;
        }

        .contact-label {
            padding: 25px 40px 20px 10px;
            width: 60%;
        }

        .userIMG {
            border-radius: 50%;
            width: 100px;
            height: 100px;
            margin: 15px auto;
            display: block;
        }

        .exprerts-container {
            margin: 15px 0px 0px 0px;
        }

        .exprerts-header {
            width: 100%;
        }

        .expert-details {
            -webkit-box-shadow: 0px 0px 3px 0px rgba(127, 127, 127, 1);
            -moz-box-shadow: 0px 0px 3px 0px rgba(127, 127, 127, 1);
            box-shadow: 0px 0px 3px 0px rgba(127, 127, 127, 1);
            float: left;
            width: 34%;
            margin: 25px 0px 0px 30px;
            height: 270px;
            transition: all 0.2s;
            position: relative;
            padding: 10px;
        }

        .expert-title {
            font-size: 14px;
            text-align: center;
            font-family: ShellMedium, sans-serif;
            line-height: 15pt;
        }

        .expert-description {
            margin: 5px 0px 15px 0px;
            font-size: 12px;
            color: #6f6f6f;
            text-align: center;
            font-family: ShellMedium, sans-serif;
            word-break: break-all;
        }

        #about-edit {
            position: absolute;
            left: 1000px;
            z-index: 1;
            transition: 0.5s ease-in-out;
            width: 100%;
            background-color: #fff;
        }

        #about-edit.active-content {
            left: 0px;
            position: relative;
        }

        #edit-contacts {
            position: absolute;
            left: 1000px;
            z-index: 1;
            transition: 0.5s ease-in-out;
            width: 100%;
            background-color: #fff;
            top: -5px;
        }

        #edit-contacts.active {
            left: 0px;
            position: relative;
            height: 300px;
        }

        .not-active {
            height: 0px !important;
            opacity: 0;
        }

        .add-field {
            margin-bottom: 5px !important;
        }

        .exists-div {
            margin-bottom: 20px;
            line-height: 1.8rem;
        }

        .existing-contact {
            margin-bottom: 2px;
            font-family: ShellBold, sans-serif;
            font-weight: bold;
        }

        .add-form {
            margin-top: 10px;
            margin-left: 10px;
        }

        .arrow-back {
            margin-top: -2px;
            font-size: 1.2rem;
            cursor: pointer;
            margin-right: 9px;
        }

        .contact-footer {
            position: absolute;
            margin-top: -19px;
            margin-left: 28%;
        }

        .contact-footer > button {
            width: 125px !important;
            margin-right: 10px;
        }

        .img-add {
            border-radius: 16px;
            display: none;
        }

        .remove-contact {
            float: right;
        }

        .remove-contact:hover {
            text-decoration: underline;
            cursor: pointer;
        }

        .remove-contact > a {
            color: #e58271 !important;
        }

        .text-nocontact {
            color: #dc3545;
        }

        .expert-details:hover {
            cursor: pointer;
        }

        .expert-details:hover > div > div > .expert-title {
            text-decoration: underline !important;
        }

        .add-many {
            margin-top: 12%;
            margin-left: 5%;
            font-size: 1.5rem;
            font-family: ShellBold, sans-serif;
            font-weight: bold;
        }

        .edit-button-contact {
            cursor: pointer;
            color: #0078d7;
        }

        .edit-button-contact:hover {
            text-decoration: underline;
        }

        @media all and (-ms-high-contrast: none) {
            .contact-footer {
                margin-top: -40px;
                margin-left: 11%;
            }
        }
    }
}
