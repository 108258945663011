#mentors {
    .mentors-search {
        margin-top: 5%;
    }

    .mentors_search_link {
        text-align: left;
        color: #0099ba !important;
        font-weight: 700;
        line-height: 2;
        margin-top: 2%;
        border-bottom: 1px solid #ddd;
        padding: 0 0 6px 0px;
        display: flex;
    }

    .export-excel {
        margin-left: 75%;
        margin-bottom: 2%;
        margin-top: -4%;
    }

    .addMentorsptag a {
        color: #0099ba !important;
    }

    .addMentorsLink {
        margin-left: 36%;
        margin-top: 15px;
        font-weight: 700;
    }

    a.Mentors_Community {
        color: #0099ba !important;
        margin-right: 4%;
    }

    a.Mentors_Community_shell {
        color: #0099ba !important;
    }

    .mentors-experts:hover {
        transform: scale(1.05);
        border-radius: 6px;
        box-shadow: 2px 1px 4px 2px rgba(0, 0, 0, 0.1);
    }

    .pagination-div {
        margin-top: 10%;
        margin-bottom: 10%;
    }

    .mentors-cards {
        display: flex;
        flex-wrap: wrap;
        min-height: 123px;
        padding: 5px;

        .title {
            margin-top: 5px;
            color: #58595b;
            text-align: left;
            padding-left: 100px;
            font-family: ShellBook, sans-serif;
            font-size: 13pt;
        }
        /*.title a {
            font-family: ShellBook;
            color: #0099ba;
            font-size: 13pt;
            cursor: pointer;
        }*/
        .category {
            margin: 8px 0;
            color: #0099ba;
            padding-left: 100px;
            text-align: left;
            font-family: ShellMedium, sans-serif;
        }

        .description {
            padding-left: 100px;
            text-align: left;
            padding-right: 10px;
            font-family: ShellBook, sans-serif;
        }

        .ask_me_about {
            padding-left: 100px;
            text-align: left;
            padding-right: 10px;
            font-family: ShellBook, sans-serif;
        }

        .no-mentors {
            width: 80%;
            margin: auto;
            margin-top: 50px;
        }



        .mentors-experts {
            width: 48%;
            border: 1px solid #ccc;
            margin: 10px 20px 10px 0px;
            padding: 20px;
            position: relative;
            box-shadow: 2px 1px 4px 2px rgba(0, 0, 0, 0.1);

            img {
                position: absolute;
                width: 80px;
                height: 80px;
                left: 20px;
                top: 25px;
                border-radius: 100px;
            }
        }

        .mentors-experts:hover {
            background-color: #f1f1f1;
            cursor: pointer;
        }
    }
}

#mentors_click_modal {
    border: 0 none !important;

    .modal-content {
        border-bottom: 0 none !important;
        border-top: 0 none !important;
        border-right: 0 none !important;
        border-left: 0 none !important;
    }

    .modal-header {
        display: inline !important;
        border-bottom: 0 none !important;
        border-top: 0 none !important;
        border-right: 0 none !important;
        border-left: 0 none !important;
    }

    .modal-title {
        height: 145px;
    }

    .modal-header .close {
        top: -160px !important;
    }

    .mentors_usr_info {
        color: #58595b;
        font-size: 13pt;
        font-family: ShellMedium, sans-serif;
        text-align: center;
    }

    .mentors_usr_img img {
        border-radius: 100%;
        width: 80px;
        height: 80px;
    }

    .mentors_usr_title {
        font-weight: 700;

        a {
            color: #0099ba !important;
            font-size: 13pt;
            font-family: shellMedium, sans-serif;
            cursor: pointer;
        }

        a:hover {
            border-bottom: 0 none !important;
            border-top: 0 none !important;
            border-right: 0 none !important;
            border-left: 0 none !important;
        }
    }

    .mentors_usr_category {
        color: #0099ba;
        border: none;
    }

    .mentors_usr_category:hover {
        border-bottom: 0 none !important;
        border-top: 0 none !important;
        border-right: 0 none !important;
        border-left: 0 none !important;
    }

    h3 {
        font-size: 1rem;
        color: #58595b;
        font-family: ShellMedium, sans-serif;
    }

    p {
        font-size: 11pt;
        color: #58595b;
        font-family: shellbook, sans-serif;
    }

    .mentors_right_div {
        margin-left: 4%;
    }

    .mentors_left_div {
        max-width: 48%;
    }

    .skills, .Ask_me_about, .intersets {
        padding-bottom: 2%;
    }

    .mentors_usr_details {
        display: flex;
    }

    .modal-body {
        border-bottom: 0 none !important;
        border-top: 0 none !important;
        border-right: 0 none !important;
        border-left: 0 none !important;
    }

    .modal-footer {
        justify-content: center;
        font-size: 12pt;
        font-family: shellbook, sans-serif;
        font-weight: 700;
        border-bottom: 0 none !important;
        border-top: 0 none !important;
        border-right: 0 none !important;
        border-left: 0 none !important;
    }

    .modal-footer a {
        color: #0099ba;
        cursor: pointer;
    }

    .modal-footer:hover {
        border-bottom: 0 none !important;
        border-top: 0 none !important;
        border-right: 0 none !important;
        border-left: 0 none !important;
    }

    .modal-footer a:hover {
        border-bottom: 0 none !important;
        border-top: 0 none !important;
        border-right: 0 none !important;
        border-left: 0 none !important;
    }
}
