.edit-banner-form {



    .banner-review  {
        height: 240px;
        border:1px solid #dee2e6;
        text-align: left;
        position: relative;
        background-size: cover;
        background-color: #ddd;
    }

    .banner-review > div {
        position: absolute;
        width: 100%;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.6);
        color: #fff;
        padding: 10px;
        font-size: 0.9em;
    }

    .banner-review-title {
        font-size: 1rem;
        font-weight: bold;
        margin-bottom: 10px;
    }

    span.author {
        font-size: 0.8rem;
    }

    .banner-review.white > div {
        background-color: rgba(255, 255, 255, 0.9);
        color: #58595b;
    }

    .image-preview {
        max-width: 100%;
    }

    .uploadIcon
    {
        display: none;
    }

    .fileContainer
    {
        display: block;
        padding: 0px;
        margin: 0px;
        box-shadow: none;
        border-radius: 0px;
    }

    .form-label i {
        color: red;
    }
    
    .loading-text {
        display: block;
        width: 230px;
        position: relative;
        left: -75px;
        top: 60px;
        background-color: rgba(255,255,255, 0.8);
        padding-left: 5px;        
    }

    input:invalid {
        border-color: #dc3545;
        padding-right: calc(1.5em + .75rem);
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
        background-repeat: no-repeat;
        background-position: right calc(.375em + .1875rem) center;
        background-size: calc(.75em + .375rem) calc(.75em + .375rem);
    }
    
}