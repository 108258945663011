#menu-modal-delete {
    height: 85%;
    color: #0099ba;
    /* header of the popup*/
    .emptydiv {
        width: 400px;
    }

    .modal-header {
        color: #212529 !important;
    }

    .deleteclosex {
        background-color: #fff !important;
        color: black !important;
        border-color: #fff !important;
    }

    .arrow-back-categorydelete {
        margin-top: 11px;
        font-size: 1.2rem;
        cursor: pointer;
    }

    .crosssimbol, .crosssimbol:hover {
        color: #212529 !important;
        background-color: #fff !important;
        border-color: #fff !important;
    }

    .card-header:hover {
        background-color: #f2f3f5;
    }

    .card-header:before {
        display: table;
        width: 14px;
        //height: 8px;
        border-top: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
        top: 21px;
        position: absolute;
        left: 10px;
    }

    .card-header:after {
        content: "\f078";
        font-family: FontAwesome, sans-serif;
        right: 20px;
        position: absolute;
        top: 14px;
        transition: all 200ms;
    }

    .card-header.active:after {
        transform: rotateZ(-180deg);
    }
    /* body of the popup*/

    .card {
        border: 0px;
        border-radius: 0px;
        height: auto;
        // min-height: 70%;
        overflow: auto;
        max-height: 600px;
    }

    .Categorylinks {
        margin-left: 4% !important;
        font-size: 22px;
        font-family: shellBook, sans-serif;
        width: 100%;
    }

    .MainLinkCategory {
        width: 60%;
    }

    .deleteCategorylong {
        position: absolute;
        right: 40px;
        color: #e58271 !important;
        font-size: 22px;
        font-family: shellBook, sans-serif;
        margin-top: -74px;
    }

    .deleteCategory {
        position: absolute;
        right: 40px;
        color: #e58271 !important;
        font-size: 22px;
        font-family: shellBook, sans-serif;
        margin-top: -35px;
    }

    .deleteCategory a {
        color: #e58271 !important;
    }

    .deleteCategory:hover {
        background-color: #fff !important;
        cursor: pointer;
    }

    .onDeleteMainLinkCategory:hover {
        background-color: #fff !important;
        cursor: pointer;
    }

    .SubLinkCategory {
        font-family: shellBook, sans-serif;
        font-size: 22px;
        text-align: left;
        color: #212529;
        margin-left: 30px;
        width: 60%;
    }

    .SubLinkCategory {
        span[id*="Sublink-title-"] {
            width: 400px;
            font-family: ShellBold, sans-serif;
        }
    }

    .delete-sublink {
        color: #0078d7 !important;
        margin-left: 57px;
        line-height: 40px;
        margin-top: 20px;
        margin-bottom: 20px;
        width: 60%;
    }

    .linkTitle {
        width: 89%;
        font-size: 20px;
        font-family: shellBook, sans-serif;
        margin-top: -12px;
    }

    .delete-subcategorylong {
        position: absolute;
        right: 50px;
        font-size: 21px;
        color: #e58271 !important;
        top: auto !important;
        margin-top: -55px;
    }

    .delete-subcategory {
        position: absolute;
        right: 50px;
        font-size: 21px;
        color: #e58271 !important;
        top: auto !important;
        margin-top: -45px;
    }

    .delete-subcategory a, .delete-subcategorylong a {
        color: #e58271 !important;
    }

    .delete-subcategory:hover, .delete-subcategorylong:hover {
        background-color: #fff !important;
        cursor: pointer;
    }

    .card-body {
        padding: 5px 0px 5px 25px;
    }

    .modal-content {
        overflow: hidden;
        height: auto;
        //min-height: 95%;
        margin-top: 80px !important;
        margin-left: -90px;
        width: 125%;
    }

    .modal-body-categorydelete {
        margin-top: 0% !important;
    }

    .links-wrapperdelete > div {
        color: #58595b;
        ;
        padding: 10px 0px 0px 40px;
        position: relative;

        span {
            position: absolute;
            top: 10px;
            font-size: 21px;
            font-family: shellBook, sans-serif;
        }
    }

    .links-wrapperdelete div div:before {
        display: table;
        width: 14px;
        //height: 8px;
        border-top: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
        top: 19px;
        color: #58595b;
        position: absolute;
        font-size: 14px;
        left: 10px;
    }


    .active > .div {
        min-height: 0px !important;
    }

    .delete-DeleteMainCategory-height {
        min-height: 530px;

        .deletecontent {
            min-height: 260px;
        }

        .deletecategoryname {
            height: 240px;
        }
    }

    .delete-link-height {
        //min-height: 37%;
        height: 310px;

        #category-wrapper-delete {
            min-height: 100%;
        }
    }

    .long-subcategory-name, .Categorylinkslong, .linkTitlelong {
        line-height: 25px !important;
    }

    .linkTitlelong {
        font-size: 20px;
        width: 80%;
    }

    .delete-DeleteCategory-height {
        min-height: 440px;

        .all-links-in-sublinkconfirmDeleteCategory {
            height: 102px;
        }
    }

    .delete-link-card-height {
        // height: 110px;
        min-height: 307px;
    }
    /* body of the next-popup*/

    div#category-wrapper-delete {
        width: 100%;
        position: absolute;
        z-index: 1;
        //height: 781px;
        transition: 0.6s ease-in;
        left: 1005px;
        background-color: white;
    }

    div#category-wrapper-delete.active {
        left: 0px !important;
    }

    div#category-wrapper-delete.nactive {
        left: 500px !important;
    }

    .deleteconfmessage {
        font-family: shellBook, sans-serif;
        padding-left: 5%;
        width: 83%;
        font-size: 1.1rem;
        text-align: left;
        color: #404040;
        padding-top: 15px;
        padding-bottom: 10px;
    }

    .deletecontentdiv {
        padding-left: 7.5%;
        padding-top: 0%;
    }

    .deletecategoryname {
        font-family: shellBook, sans-serif;
        font-size: 22px;
        text-align: left;
        color: #0099ba;
        overflow: auto;
        //height: 350px;
        height: auto;
        max-height: 500px;
    }

    .SubCategorylinksconfirmDeleteMainCategor {
        margin-top: 20px;
    }

    .SubLinkCategoryDeleteMainCategor {
        font-family: shellBook, sans-serif;
        font-size: 22px;
        text-align: left;
        color: #212529;
        margin-left: 30px;
    }

    .all-links-in-sublinkconfirmDeleteMainCategory {
        margin-top: -41px;
        margin-bottom: 60px;
    }

    .delete-sublinkDeleteMainCategor {
        color: #0078d7 !important;
        margin-left: 57px;
        line-height: 35px;
        margin-top: 50px;
        margin-bottom: -42px;
    }

    .deletelinkcategoryname {
        text-align: left;
        color: #404040;
    }

    .DeleteCategoryconfirmDeleteCategory {
        font-family: shellBook, sans-serif;
        font-size: 22px;
        text-align: left;
        color: #212529;
        margin-left: 30px;
    }

    .all-links-in-sublinkconfirmDeleteCategory {
        overflow: auto;
        min-height: 90px;
        //height: auto;
        // height: 320px;
    }

    .delete-sublinkDeleteCategory {
        color: #0099ba !important;
        margin-left: 57px;
        line-height: 35px;
        margin-top: 50px;
        margin-bottom: -42px;
    }

    .linkTitleDeleteCategory {
        width: 75%;
        font-size: 20px;
        font-family: shellBook, sans-serif;
        margin-top: -42px;
    }

    .deletelinkconfirmDeletelinks {
        font-family: shellBook, sans-serif;
        font-size: 22px;
        text-align: left;
        color: #0099ba;
        // margin-top: 31px;
    }

    .modaldeleteinactive {
        .card {
            //min-height: 265px;
            max-height: 100px;
        }
    }

    .modaldeleteactive {
        height: auto !important;
    }
    /* Footer of the poup*/
    // .deleteCancel:hover , .deleteclose:hover
    // {
    //     background-color: #fff !important;
    //     color: black !important;
    //     border-color: #6c757d !important;
    // }

    .deleteclose, .deleteCancel {
        background-color: #fff !important;
        color: black !important;
        border-color: #6c757d !important;
    }

    .modal-footer-categorydelete {
        padding-left: 0% !important;
        padding-right: 0% !important;
    }

    .modal-footer-categorydelete, .modal-footer {
        background-color: #fff !important;
    }

    .modal-footer {
        border-top: none !important;
        margin-right: 30px;
    }

    .before-none:before {
        border: none !important;
    }
    /* css not used*/
    .link-edit-wrapper {
        display: none;
        z-index: 1;
        position: relative;

        input {
            width: 98%;
            margin-bottom: 5px;
            padding: 5px;
            font-size: 14px;
            font-family: ShellBook, sans-serif;
        }
    }

    div.exapand-alldelete {
        text-align: right;
        display: block;
        color: #0099ba;
        margin-right: 10px;
    }

    .deletelink a {
        color: #e58271 !important;
    }

    .SubCategorylinksconfirm {
        margin-bottom: 50px;
    }

    .deletelinkconfirm {
        font-family: shellBook, sans-serif;
        font-size: 22px;
        text-align: left;
        color: #0099ba;
        margin-top: 31px;
    }

    .DeleteCategoryconfirm {
        font-family: shellBook, sans-serif;
        font-size: 22px;
        text-align: left;
        color: #212529;
        margin-left: 30px;
    }

    .all-links-in-sublinkconfirm {
        //margin-top: -30px;
        overflow: auto;
        //height: 330px;
    }

    .linkscount {
        font-family: shellBook, sans-serif;
        font-size: 1rem;
        text-align: left;
        color: gray;
    }

    .deletelinksdiv .linksbarsym {
        padding-left: 6%;
        padding-top: 3%;
        font-family: shellBook, sans-serif;
        font-size: 2rem;
        text-align: left;
    }

    .deleteinglinks {
        padding-left: 10%;
        margin-top: -4.8%;
        color: #009FE1;
    }

    .modal-footer {
        padding-top: 2%;
        padding-right: 3.2rem;
    }

    .deletelinksdiv {
        overflow: auto;
        margin-left: -3%;
    }

    .deletelinkcategorynamediv {
        padding-left: 8%;
        padding-top: 2%;
    }

    .deleteitemsselected {
        font-family: shellBookx, sans-serif;
        padding-left: 5%;
        width: 83%;
        font-size: 1.1rem;
        text-align: left;
        color: #404040;
    }

    .card-header {
        background-color: transparent;
        position: relative;
        padding-left: 34px;
        cursor: pointer;
        border-bottom: none;
        font-family: ShellBook, sans-serif;
        font-size: 20px;
    }

    .MainLinkCategorydeletemessage {
        font-size: 22px;
        font-family: shellBook, sans-serif;
        color: #212529;
        text-align: center;
    }
    /* affecting css*/
}
