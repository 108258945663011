.customwebpart-widget {
    color: #58595b;

    .customwebpart-cards {
        text-align: right;

        .unhide {
            text-align: right;
            color: #0099ba;
            cursor: pointer;
            float: right;
            font-size: 17pt;
            font-family: ShellBook, sans-serif;

            &:hover {
                text-decoration: underline;
            }
        }

        .announcement-container {
            position: relative;
            overflow: auto;
            //border: 1px solid #ddd;
            padding: 2px;
            font-family: ShellMedium, sans-serif;
            margin-bottom: 20px;
            text-align: left;
            transition: all 500ms;




            h1 {
                font-size: 16px;
                font-weight: bold;
                line-height: 26px;

                &:hover {
                    cursor: pointer !important;
                }
            }

            .summary, .date {
                margin-top: 10px;
            }
        }

        .webpart-header {
            text-align: left;
            font-family: ShellMedium, sans-serif;
            margin-top: 5px;
            margin-bottom: 2%;
            font-size: 16pt;
            overflow-wrap: break-word;
            width: 100%;
        }

        .dropdown-item {
            color: #0099ba !important;
        }

        a.dropdown-item:hover {
            color: #0099ba !important;
        }

        .hidden-announcement {
            background-color: #f5f5f5;
        }

        #edit.active {
            opacity: 1;
        }

        a.link {
            cursor: pointer;
            color: #0099ba !important;
            margin-bottom: 20px;
            display: inline-block;
            font-size: 18px;

            svg {
                position: relative;
                top: -3px;
                font-size: 18px;
            }
        }

        a.link:hover {
            text-decoration: underline;
            color: #0099ba !important;
        }

        a.refresh {
            color: #e58271;
        }

        #edit {
            position: absolute;
            z-index: 1;
            font-size: 20px;
            opacity: 0;
            right: 10px;
            top: 10px;

            .dropdown-toggle::after {
                display: none;
            }

            svg {
                font-size: 26px;
                color: #e1631c;
            }

            .btn-primary {
                border: none;
                background-color: transparent;
                //position: relative;
                left: 10px;
            }

            .btn:focus, .btn:active:focus, .btn.active:focus {
                outline: none;
                box-shadow: none;
            }
        }

        a.webpart-header-link {
            color: #58595b;
        }

        a.webpart-header-link:hover {
            text-align: left;
            font-family: 'ShellMedium', sans-serif;
            font-size: 16pt;
            color: #0099ba;
            cursor: pointer;
            text-decoration: underline;
        }
    }

    .pagination-loader {
        text-align: left;
    }

    .no-announcments-message {
        margin-top: 20px;
        text-align: center;
        font-weight: bold;
    }
}
