#events {
  width: 100%;
}

#events,
#events-dialog {
    .events-wrapper {
        margin-top: 40px;
    }

    .hidden-webpart {
        background-color: #f5f5f5;
    }

    .events-loading span {
        margin-bottom: 10px;
    }

    .header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        position: absolute;
        width: 90%;
        background-color: #fff;
        height: 30px;
        //z-index: 1;
        left: 5px;
        top: 0px;

        h1 {
            font-size: 20px;
            font-weight: bold;
        }

        a {
            position: initial;
            color: #0099ba;
        }

        .hide-unhide {
            text-align: right;
            color: #0099ba;
            cursor: pointer;
            float: right;
            font-size: 14pt;
            font-family: ShellBook, sans-serif;
            margin: -3px 0px 0px 23px;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .events {
        //display: flex;
        margin-bottom: 14px;
        border: 1px solid #ccc;
        padding: 10px 0px;
        transition: all 0.2s;
        min-height: 86px;
        cursor: pointer;
    }

    .events:hover {
        transform: scale(1.05);
        border-radius: 6px;
        box-shadow: 2px 1px 4px 2px rgba(0, 0, 0, 0.1);
    }

    .download-invite {
        border: 1px solid #ccc;
        padding: 3px;
        font-size: 14px;
        width: 50%;
        text-align: center;
        margin-top: 8px;

        svg {
            font-size: 20px !important;
            color: #000000 !important;
            position: relative;
            top: -3px;
        }

        a {
            top: 0px !important;
        }

        span {
            font-weight: bold;
            color: #0099BA !important
        }
    }

    .cal-box {
        //width: 40%;
        //border-right: 1px solid #ccc;
        position: relative;
        //margin-right: 20px;
        margin-left: 8px;
        margin-right: 8px;
    }

    .event-title {
        font-size: 18px;
        margin-bottom: 5px;
        margin-top: 5px;
        font-family: ShellMedium, sans-serif;
    }

    .event-notes {
        font-size: 12px;
        margin-top: 6px;
        word-break: break-word;
    }

    .event-location {
        font-size: 12px;
        color: #6f6f6f;
        font-family: ShellMedium, sans-serif;
        //display: flex;
        justify-content: space-between;
    }

    .recurring-info {
        display: flex;
    }

    .recurring-indicator {
        background-color: #757575;
        width: 140px;
        margin-top: 2px;
        color: #FFFFFF;
        font-size: 14px;

        svg {
            color: #FFFFFF;
        }
    }

    .recurring-frequency {
        margin-left: 10px;
        background-color: #007bff;
        text-align: center;
        color: white;
        min-width: 60px;
        padding: 2px;
    }

    .details {
        //width: 75%;
        text-align: left;
        position: relative;
        margin-left: 8px;
        margin-right: 10px;
    }

    .month {
        font-size: 14px;
        text-align: left;
    }

    .time {
        position: absolute;
        top: 46px;
        text-align: center;
        width: 100%;
        padding: 4px 0px;
        font-size: 12px;
        font-family: "Roboto", sans-serif;
    }

    .local-time {
        margin-top: 15px;
        text-align: center;
        width: 100%;
        padding: 4px 0px;
        font-size: 12px;
        font-family: "Roboto", sans-serif;
    }

    .date {
        font-size: 20px;
        font-family: "Roboto", sans-serif;
        position: relative;
    }

    .MsoNormalTable {
        width: -webkit-fill-available !important;
    }

    .dialog-event {
        text-align: center;
        border: none;
        display: flex;

        .cal-box {
            width: 20%;
            border: none;
        }

        .time {
            position: inherit;
            top: inherit !important;
        }

        .event-file svg:first-child {
            position: relative;
            top: -3px;
        }

        .event-file a svg:last-child {
            margin-left: 10px;
            top: -2px;
            color: #0099ba !important;
            position: relative;
        }

        .month {
            text-align: center !important;
        }

        .cal-box {
            margin-right: 20px !important;
        }
    }

    .dialog-event:hover {
        transform: scale(1);
        box-shadow: none;
        border-radius: 0px;
    }

    .edit-dots {
        width: 0px;
        left: 160px;
        top: -4px;

        svg {
            color: #0078d7 !important;
        }
    }

    .no-events {
        width: 80%;
        margin: auto;
        margin-top: 50px;
    }

    .add-event {
        color: #0099ba;
        cursor: pointer;
        margin-top: 20px;

        svg {
            color: #0099ba;
            position: relative;
            top: -2px;
        }
    }
}

#events-dialog {
  // width: 60% !important;
  // max-width: 60% !important;

  a {
    font-size: 12px;
    margin-left: 20px;
  }

  .dialog-event {
    cursor: default;
    padding-top: 40px;
    margin-bottom: 0px;
  }

  .modal-footer {
    justify-content: center;
  }

  .modal-header {
    position: absolute;
    right: 0px;
    z-index: 9;
    border: none;
  }

  .event-title {
    font-weight: bold;
  }

  .event-description {
    font-size: 12px;
    margin-top: 6px;
    word-break: break-word;
  }

  .event-description img {
    max-width: 630px !important;
}

  .event-file {
    font-size: 14px;
  }

  .local-time {
    margin-top: 0px !important;
  }
}
