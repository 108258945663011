
#page-loader {
    position: absolute;
    z-index: 2;
    height: 100%;
    width: 100%;
    background-color: #fff;
    font-family: ShellMedium, sans-serif;

    > div {
        position: relative;
        width: 100%;
        height: 400px;
    }

    img {
        position: absolute;
        top: 16%;
    }

    .spinner-border {
        position: absolute;
        top: 34%;
        left: 50.9%;
    }

    .message {
        position: absolute;
        top: 46%;
        left: 49%;
        z-index: 1999;
    }
}
