#all-events {
    width: 100%;
    padding: 40px 40px;

    .add-events-toolbar{
        display: inline-flex;
        position: absolute;
        right: 0;
    }

    .events:last-of-type {
        margin-bottom: 50px;
    }

    .event-location {
        margin-top: 5px;
    }

    .all-events-from-icon {
        position: relative;
        top: 6px;
        right: 14px;
    }

    .all-events-to-icon {
        position: relative;
        top: 6px;
        right: 14px;
    }

    .to {
        position: relative;
        top: 3px;
    }
}


#all-events, #all-events-dialog {


    .react-datepicker-wrapper:nth-child(1) {
        margin-left: 10px !important;
    }


    .event-types {
        text-align: left;
        position: relative;

        a.add-link-btn {
            //position: absolute;
            //right: 0px;
            padding: 10px;
            cursor: pointer;
            color: #0099ba;


            svg {
                position: relative;
                top: -3px;
                font-size: 18px;
            }
        }

        a.add-link-btn:hover {
            text-decoration: underline;
        }
    }

    span.nav-link {
        color: #58595b;
        margin-right: 20px;
        padding: 5px 15px;
        font-weight: bold;
        cursor: pointer;
        display: inline;
    }

    span.nav-link.active {
        border-bottom: 4px solid #66c2d6;
    }

    span.nav-link:hover {
        border-bottom: 4px solid #ccebf1;
    }

    .date-filter {
        font-size: 14px;
        margin-top: 40px;
        text-align: left;
        padding-left: 40px;
        display: flex;
    }

    .react-datepicker-wrapper {
        margin: 0 20px;
    }

    .clear-filter {
        color: #0099ba;
    }



    .events-wrapper {
        margin-top: 40px;
    }

    .events-loading span {
        margin-bottom: 10px;
    }

    .header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        position: fixed;
        width: 90%;
        background-color: #fff;
        height: 30px;
        z-index: 1;
        left: 5px;

        h1 {
            font-size: 20px;
            font-weight: bold;
        }

        a {
            position: initial;
            color: #0099ba;
        }
    }

    .events {
        display: flex;
        margin-bottom: 14px;
        border: 1px solid #ccc;
        padding: 10px 0px;
        transition: all 0.2s;
        min-height: 86px;
        cursor: pointer;
    }

    .events:hover {
        transform: scale(1.05);
        border-radius: 6px;
        box-shadow: 2px 1px 4px 2px rgba(0,0,0, 0.1);
    }

    .download-invite {
        margin-left: 20px;
        position: absolute;
        //top: 5px;
        right: 0;
        border: 1px solid #ccc;
        padding: 5px;

        svg {
            font-size: 20px !important;
            position: relative;
            top: -4px;
            color: #000000 !important;
            margin-right: 8px;
        }

        span {
            font-weight: bold;
            color: #0099BA !important;
            font-size: 16px;
        }
    }

    a.link {
        cursor: pointer;
        color: #0099ba;
        margin-bottom: 20px;
        display: inline-block;
        font-size: 18px;

        svg {
            position: relative;
            top: -3px;
            font-size: 18px;
        }
    }

    a.link:hover {
        text-decoration: underline;
    }

    a.refresh {
        color: #e58271;
        right: 0;
        position: absolute;
    }

    .cal-box {
        width: 15%;
        border-right: 1px solid #ccc;
        position: relative;
        margin-right: 20px;
    }

    .event-title {
        font-size: 18px;
        margin-bottom: 0px;
        font-family: ShellMedium, sans-serif;
    }

    .event-tools {
        display: flex;
    }

    .recurring-indicator {
        background-color: #757575;
        width: 130px;
        margin-top: 8px;
        color: #FFFFFF;
        font-size: 14px;
        padding: 3px;

        svg {
            color: #FFFFFF;
        }
    }

    .recurring-frequency {
        margin-left: 10px;
        margin-top: 8px;
        background-color: #007bff;
        color: white;
        text-align: center;
        min-width: 60px;
        padding: 2px;
        font-size: 14px;
    }

    .event-location {
        font-size: 12px;
        color: #6f6f6f;
        font-family: ShellMedium, sans-serif;
        display: flex;
        justify-content: flex-start;
        margin-bottom: 15px;


        span {
            width: 260px;
            line-height: 14px;
            margin-top: 5px;
        }
    }

    .details {
        width: 75%;
        text-align: left;
        position: relative;
    }

    .month {
        font-size: 14px;
    }

    .time {
        position: absolute;
        top: 46px;
        text-align: center;
        width: 100%;
        padding: 4px 0px;
        font-size: 12px;
        font-family: 'Roboto', sans-serif;
    }

    .local-time {
        margin-top: 15px;
        text-align: center;
        width: 100%;
        padding: 4px 0px;
        font-size: 12px;
        font-family: 'Roboto', sans-serif;
    }

    .date {
        font-size: 20px;
        font-family: 'Roboto', sans-serif;
        position: relative;
    }




    .dialog-event {
        text-align: center;
        border: none;

        .cal-box {
            width: 20%;
            border: none;
        }

        .time {
            position: inherit;
            top: inherit !important;
        }

        .event-file svg:first-child {
            position: relative;
            top: -3px;
        }

        .event-file a svg:last-child {
            margin-left: 10px;
            top: -2px;
            color: #0099ba !important;
            position: relative;
        }
    }

    .dialog-event:hover {
        transform: scale(1);
        box-shadow: none;
        border-radius: 0px;
    }

    .edit-dots {
        width: 100px;
        right: 0px;
        top: -1px;
        background: transparent;
    }

    .no-events {
        width: 80%;
        margin: auto;
        margin-top: 50px;
    }

    .add-event {
        color: #0099ba;
        cursor: pointer;
        margin-top: 20px;

        svg {
            color: #0099ba;
            position: relative;
            top: -2px;
        }
    }

    .react-datepicker-popper {
        z-index: 4;
    }

    .pagination-loader {
        text-align: left;
    }

    .year {
        text-align: left;
        padding-left: 35px;
        font-family: ShellMedium, sans-serif;
        font-weight: bold;
        margin-bottom: 10px;
    }

    .events {
        opacity: 0;
        animation: fadeIn 500ms;
        animation-delay: 200ms;
        animation-fill-mode: forwards;
        position: relative;
    }

    @keyframes fadeIn {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }
}
.modal-lg{
    max-width: 835px !important;
}
#all-events-dialog {
    // width: 60% !important;
    // max-width: 60% !important;

    a {
        font-size: 12px;
        margin-left: 20px;
    }

    .dialog-event {
        cursor: default;
        padding-top: 40px;
        margin-bottom: 0px;
    }

    .modal-footer {
        justify-content: center;
    }

    .modal-header {
        position: absolute;
        right: 0px;
        z-index: 9;
        border: none;
    }

    .event-title {
        font-weight: bold;
    }

    .event-description {
        font-size: 12px;
        margin-top: 6px;
        word-break: break-word;
    }

    .event-description img {
        max-width: 630px !important;
    }
    .event-notes {
        font-size: 12px;
        margin-top: 6px;
        word-break: break-word;
    }
    .event-file {
        font-size: 14px;
    }
    .local-time{
        margin-top: 0px !important;
    }
    .MsoNormalTable{
        width:  -webkit-fill-available !important;
    }
}

#delete-events-dialog {

    .modal-header {
        padding-top: 10px;
        padding-bottom: 10px;

        .close {
            position: absolute;
            right: 10px;
            top: 3px;
        }
    }

    .modal-body {
        padding: 20px;

        .delete-event-body {

            .warning {
                margin-bottom: 20px;
            }

            .gmt {
                color: #58595b;
                margin-left: 5px;
            }

            .delete-event-title {
                font-family: 'ShellMedium', sans-serif;
                font-weight: bold;
                margin-left: 20px;
                margin-bottom: 10px;
            }

            .delete-event-info {
                margin-left: 20px;
            }

            .delete-event-day {
                display: inline-block;
                margin-right: 10px;
            }

            .delete-event-time {
                display: inline-block;
                margin-left: 10px;
            }

            .delete-event-description {
                margin-top: 10px;
            }

            .event-notes {
                margin-top: 5px;
            }
        }
    }
}
