#experts {
    width: 100%;
    margin-top: 20px;

    .section-title {
        font-size: 20px;
        font-weight: bold;
    }

    .userIMG {
        border-radius: 50%;
        margin: 15px 0px 15px 0px;
        height: 120px;
        width: 125px;
    }

    .exprerts-header {
        width: 100%;

        a {
            color: #0099ba;
            position: relative;
            top: 10px;
            right: 25px;
        }

        .hide-unhide {
            color: #0099ba;
            cursor: pointer;
            font-size: 14pt;
            font-family: ShellBook, sans-serif;
            position: absolute;
            right: 10px;
            top: 27px;

            &:hover {
                text-decoration: underline;
            }

            .hide {
                right: 100px;
                position: absolute;
            }
        }
    }

    .expert-details {
        border: 1px solid #ccc;
        float: left;
        width: 18%;
        margin: 0px 15px 0px 5px;
        transition: all 0.2s;
        position: relative;
        padding-bottom: 20px;
        min-height: 255px;
    }

    .expert-title {
        line-height: 16pt;
        margin: 5px 0px 0px 0px;
        font-size: 14px;
        font-family: ShellMedium, sans-serif;
    }

    .expert-description {
        margin: 5px 0px 0px 0px;
        font-size: 12px;
        color: #808285;
        font-family: ShellMedium, sans-serif;
    }

    .expert-roles-count {
        display: none;
    }

    .expert-role-count {
        background: #F0F0F0;
        border-radius: 25px;
        width: 50%;
        margin-top: 2%;
        padding: 1%;
        margin-left: 25%;
        font-family: ShellMedium, sans-serif;
        font-size: 14px;
        color: #595959;
    }

    .expert-role {
        margin: 5px 0px 0px 0px;
        font-size: 13px;
        color: #808285;
        font-family: ShellMedium, sans-serif;
    }

    .expert-details:hover {
        -ms-transform: scale(1.1); /* IE 9 */
        -webkit-transform: scale(1.1); /* Safari 3-8 */
        transform: scale(1.05);
        cursor: pointer;
        border-radius: 6px;
        //box-shadow: 2px 1px 4px 2px rgba(0, 0, 0, 0.1);
        box-shadow: 1px 0px 6px 4px rgba(0, 0, 0, 0.1);
    }

    .exprerts-container {
        margin: 15px 0px 0px 0px;
    }

    .hidden-webpart {
        background-color: #f5f5f5;
        overflow: auto;
    }

    .experts-viewall {
        top: 3px !important;
        color: #0099ba;
    }

    .experts-loading {
        display: flex;

        .css-jn8o09 {
            margin: 0px 5px 0px 5px;
        }
    }

    .expert-star-icon {
        background: url("../../assets/images/Expert_Star_icon.png");
        font-size: 32px;
        height: 20px;
        width: 20px;
        background-repeat: no-repeat;
        position: absolute;
        top: 110px;
        right: 50px;
        display: none;
    }

    .efml-experts {
        min-height: 300px !important;
        height: 300px !important;
    }
}
