#dragndrop-section {
    background-color: #f5f5f5;
    border: 3px #ddd;
    border-style: dashed;
    border-radius: 9px;
    height: 293px;
    margin: auto;
    text-align: center;
    width: 89%;
    margin-top: 3%;
    margin-bottom: 3%;
    transition: 0.4s;
}
#dragndrop-section.not-active {
    height: 0px;
}

.dragndrop-text{
    font-size: 2em;
    margin-top: 7%;
}
.dragndrop-section > div:focus {
    outline: none;
}

#add-event-fields {
    position: absolute;
    transition: 0.5s ease-in-out;
    width: 100%;
    background-color: #fff;  
    top: 0px; 
}
#add-event-fields.active {
    
}
.info-max {
    font-size: 0.8em;
    color: #495057;
}
.browse-outlook-invite {
    background-color: #fff;
    color: #58595b;
}
.browse-outlook-invite:hover {
    background-color: #fff ;
    color: #58595b;
}
.browse-outlook-invite:active {
    background-color: #fff ;
    color: #58595b;
}
.browse-outlook-invite:focus {
    background-color: #fff ;
    color: #58595b;
}
.star {
    color: red;
}
#add-event-fields > .form-control {
    width: 94%;
}
.location-input {
    width: 50%;
}
.react-datepicker-wrapper input {
    width: 100px !important;
}
.react-datepicker-wrapper {
    border-color: #ced4da !important;
    
}
.date-picker-row {
    display: flex;
}
.date-picker-row > .form-label {
    width: 80px;
}
.calendar-icon {
    margin-left: 5px;
}
.react-datepicker-wrapper:nth-child(1) {
    margin-left: 6% !important;
}
.react-datepicker-wrapper:nth-child(1) ~ .calendar-icon  {
    margin-left: -29% !important;
}
.checkbox-all-day {
    float: right;
}
.browse {
    margin-left: 4%;
}
.browse {
    background-color: #fff;
    color: #58595b;
}
.browse:hover {
    background-color: #fff ;
    color: #58595b;
}
.browse:active {
    background-color: #fff ;
    color: #58595b;
}
.browse:focus {
    background-color: #fff ;
    color: #58595b;
}
.add-event {
    width: 94%;
}
.add-event > .form-group {
    margin-bottom: 1.5rem !important;
}
.time-picker-span > select {
    padding: 0px;
    height: auto;
}
.time-picker-span {
    margin-left: 20px;
}
.conf-message {
    font-size: 1.6em;
}
.modal-conf {
    text-align: center;
}
.modal-150w {
    width: 100%;
}
.modal-footer-conf {
    margin-top: 4%;
    margin-right:20% ;
}
.remove-ics {
    color: #0099ba;
}
.modal-new > .modal-content {
    margin-left: 19%;
    width: 67%;
}
.browse-outlook-invite:disabled {
    margin-top: 3%;
    background-color: #fff;
    color: #58595b;
    border-color: #ddd;
    cursor: auto;
}
.remove-ics:hover {
    cursor: pointer;
    text-decoration: underline;
}
.error-new {
    float: left;
    margin-top: -4%;
    color: #dc3545;
    font-size: 0.8em;
    margin-bottom: 0px !important;
}
.error-file {
    
    margin-top: -5%;
    color: #dc3545;
    font-size: 0.8em;
    
}
.date-format {
    width: 3%;
    font-size: 0.7em;
    margin-left: 2%;
}
.modal-lg{
    max-width: 835px !important;
}
.rdw-colorpicker-wrapper {
    display: none;
}
.DraftEditor-editorContainer{
    z-index: -1;
    cursor: text;
    display: contents;
}
span.rdw-image-imagewrapper img {
    max-width: 630px;
}
#edit-event .rdw-link-wrapper .rdw-link-modal {
    left: -80px;
    height: 245px;
}