#menu-modal-add {
    .links-wrapper div {
        color: #0099ba;
        cursor: pointer;
        padding: 10px 0px 10px 40px;
        position: relative;
    }

    .links-wrapper div:hover {
        background-color: #f5f5f5;
        color: #656565;
    }

    .links-wrapper div:before {
        content: " ";
        display: table;
        width: 14px;
        height: 8px;
        border-top: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
        top: 20px;
        position: absolute;
        left: 10px;
    }
}

#add-new-menu {
    .rdw-embedded-wrapper {
        display: none;
    }

    .rdw-fontsize-wrapper {
        display: none;
    }

    .rdw-fontfamily-wrapper {
        display: none;
    }

    .rdw-link-modal {
        left: -140px !important;
    }

    .rdw-image-alignment-options-popup {
        display: none !important;
    }

    .rdw-colorpicker-wrapper {
        display: none;
    }


    .wrapper-class {
        padding: 1rem;
        border: 1px solid #ccc;
    }

    .editor-class {
        padding: 0px 12px;
        border: 1px solid #ccc;
        min-height: 200px;
    }

    .toolbar-class {
        border: 1px solid #ccc;
    }

    .lds-ring {
        position: absolute;
        top: 50px;
        left: 280px;
        z-index: 999;
        width: 210px;
    }

    span.rdw-image-imagewrapper img {
        width: 200px;
    }

    .img-txt {
        padding-top: 15px;
    }

    .img-upload-button {
        padding-top: 10px;
        max-width: 50%;
        width: 50%;
    }
}

.slide-z  {
    width: 100%;
    position: absolute;
    z-index: 1;
    top: 0%;
    height: 133px;
    transition: 1s;
    left: 500px;
    background-color: white;
    transform:translateX(-500px);
}

div#category-wrapper{
    width: 100%;
    position: absolute;
    z-index: 1;
    height: 560px;
    transition: 0.6s ease-in-out;
    left: 500px;
    background-color: white;
}
div#category-wrapper.active{
    left: 0px !important;
}
div#category-wrapper.nactive{
    left: 500px !important;
}

.add-new-category {
    float: right;
}
.add-new-category a:hover {
    text-decoration: none !important;
    color: #0099ba !important;
    cursor:pointer !important;
}
.form-controls {
    margin-bottom: 4em;
}
.modal-content {
    overflow: hidden;
    margin-top: -15px !important;
}
.modal-footer{
    border-top: none !important;
    padding-top: 0px !important;
}
.modal-footer-category{
    padding-top: 24% !important;
}
.modal-body-category{
    margin-top: 4% !important;
}
.modal-body-category > .form-controls {
    height: 70px !important;
    margin-bottom: -2em !important;
}
.arrow-back-category{
    margin-top: 11px;
    font-size: 1.2rem;
    cursor: pointer;
}
.header-category{
    width: 90%;
}
.modal-title {
    margin-left: 17px;;
}
div.custom-switch{
    padding-left: 10.25rem;
    margin-top: -24px;
}
button.button-cancel-menu{
    background-color:#fff;
    color: black;;
}

.custom-switch-1 > label {
    margin-left: 100px
}
button.button-save-menu.btn.btn-primary{
    background-color:  #0099ba;
    border-color: #0099ba;
}

.button.btn.btn-primary{
    background-color:  #0099ba;
    border-color: #0099ba;
}
.add-new-category a{
    color: #0099ba;
}

.activeMenu > .modal-body-add {
    height: 200px !important;
    transition: all 0.8s ease-in-out ;
}
#category-wrapper .btn-primary{
    background-color: #0099ba !important;
}
.modal-header .close {
    font-size: 2.5rem !important;
    font-weight: 400 !important;
    position: relative;
    top: -4px;
  } 
  .form-group > .form-label{
      margin-bottom: 0.1rem;
  }
  .form-group{
      margin-bottom: 2rem;
  }
  .custom-control-input:checked~.custom-control-label::before {
     background-color:#0099ba !important;
     border-color: #0099ba !important;
  }
  .form-control option:hover {
      background-color: #0099ba !important ;
  }
  .modal-body-add{
    //   height: 585px !important;
      transition: all 0.8s ease-in-out;
  }

  .cusotm-alert-box {
    margin-bottom: -40px;
    margin-top: 10px;
  }
