#all-experts {
    padding-top: 20px;
    text-align: right;
    margin-bottom: 200px;
    min-height: 30vh;

    .utilities {

        .export-excel {
            margin-bottom: 10px;
        }

        .expand-all {
            margin-bottom: 10px;
            color: #0099ba;
        }
    }

    .sme-underline:hover {
        text-decoration: underline;
        cursor: pointer;
    }

    .no-experts {
        float: left;
        text-align: center;
        margin-left: 35%;
        margin-top: 10%;
    }

    .export-excel {
        padding: 0px 15px 25px 0px;
    }

    .expert-toggle-button {
        float: left;
        margin-left: 50%;
        margin-top: 3%;
    }

    .accordion {


        .card-header:after {
            content: "\f078";
            font-family: FontAwesome, sans-serif;
            right: 20px;
            position: absolute;
            top: 14px;
            transition: all 200ms;
            opacity: .5;
        }

        .card-header.active:after {
            transform: rotateZ(-180deg);
        }

        .card:first-child {
            border-top: 0px !important;
        }

        .card {
            border-left: 0px;
            border-right: 0px;
            border-radius: 0px;
        }

        .card-header {
            background-color: #fff;
            text-align: left;
        }

        .card-header:hover {
            background-color: #f1f1f1;
            cursor: pointer;
        }

        .active.card-header {
            background-color: #e8f5f7;
        }

        .category-exeprts {
            display: flex;
            flex-wrap: wrap;
            min-height: 123px;

            .title {
                font-weight: bold;
                margin-top: 5px;
                color: #58595b !important;
            }

            .title a {
                color: #58595b !important;
            }

            .category {
                margin: 8px 0;
                color: #077dd4;
            }

            .description {
            }
        }

        .category-exeprts > div {
            width: 48%;
            border: 1px solid #ccc;
            margin: 10px 20px 10px 0px;
            padding: 30px;
            position: relative;
            box-shadow: 2px 1px 4px 2px rgba(0,0,0, 0.1);

            img {
                position: absolute;
                width: 80px;
                height: 80px;
                left: 30px;
                top: 18px;
                border-radius: 100px;
            }
        }

        .category-exeprts > div:hover {
            background-color: #f1f1f1;
        }

        .category-exeprts > div > div {
            padding-left: 100px;
            text-align: left;
        }
    }

    #experts-accordion {
        .card-body {
            //min-height: 300px;
        }
    }

    .sme {
        text-align: left;

        .filter-select > div {
            width: 45%;
            display: inline-block;
            margin-left: 30px;
        }

        .sme-header .col-4 span {
            display: flex;
            align-items: flex-end;
            height: 100%;
            color: #808285;
        }

        .sme-header .form-control {
            width: 40%;
        }

        .row.sme-header:hover {
            background-color: #fff;
        }

        .row {
            margin-bottom: 10px;
            padding-top: 20px;
        }

        .row:hover {
            background-color: #f1f1f1;
        }

        .sme-details-wrapper {
            width: 100%;
            display: flex;
            flex-wrap: wrap;

            .sme-details {
                margin-bottom: 20px;
                width: 48%;
                position: relative;
                box-sizing: border-box;
                max-width: 50%;
                display: flex;

                .star-img {
                    height: 20px;
                    width: 20px;
                    top: 28px;
                    left: 26px;
                    background-repeat: no-repeat;
                    background-size: 70%;
                }

                img {
                    width: 40px;
                    height: 40px;
                    border-radius: 20px;
                    margin-right: 10px;
                }

                .sme-name {
                    width: 200px;
                }
            }
        }
    }


    .skeleton-loading {
        text-align: left;
        line-height: 38px;
    }

    .experts-text {
        font-family: ShellBold, sans-serif;
        text-align: left;
        font-size: 15pt !important;
    }
}
