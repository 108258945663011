#search-results {
    margin: 0px 0 120px 0;
    padding-top: 40px;

    .no-results {
        padding-top: 20px;
    }

    .mb30 {
        margin-bottom: 30px;
    }

    .form-inline input {
        margin-right: 0px !important; /* Using improtant to override bootstrap default style */
        border-radius: .25rem 0 0 .25rem;
        border-color: #A7A9AC;
        width: 70%;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 18px;
        height: 44px;
        border-width: 2px;
    }

    .form-inline button {
        border-radius: 0 .25rem .25rem 0;
        background-color: #A7A9AC;
        border-color: #A7A9AC;
        height: 44px;
        font-size: 22px;
    }

    .form-inline {
        flex-flow: initial !important;
        WIDTH: 92% !important;
    }

    .search-icon {
        position: relative;
        top: -6px;
    }

    .txt-lft-fltrs {
        text-align: left;
        /*padding: 20px 0 0 20px;*/
        margin-left: 0px;
        /*margin-right: 10px;*/
        line-height: 5px;

        .form-group {
            margin-bottom: 1rem !important;
        }

        .form-check-input {
            margin-top: 0.7rem !important;
        }
    }


    .form-check-label {
        margin-bottom: 0;
        padding: 12px !important;
    }

    .custom-control.custom-checkbox {
        margin-bottom: 10px;
    }

    .results-count {
        text-align: left;
        display: inline-block;
        width: 100%;
        margin-top: 10px;
        color: #808185;
    }

    .Alert-info {
        margin-top: 10px;
    }

    .enterprise-search {
        width: 100%;
        text-align: left;

        .enterprise-search-text {
            width: 274px;
            height: 27px;
            left: 342px;
            top: 43px;
            font-family: ShellBook, sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 27px;
            color: #58595B;
            padding: 10px 20px 12px 0px;
        }

        .enterprise-search-button {
            font-family: ShellBook, sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 21px;
            color: #363D44;
            flex: none;
            order: 0;
            flex-grow: 0;
            background: none !important;
            box-sizing: border-box;
            flex-direction: row;
            align-items: center;
            position: absolute;
            width: 200px;
            height: 40px;
            border: 1px solid #A7A9AC;
            box-shadow: 0px 1px 1px 0px;
            border-radius: 6px;
        }
    }

    .event-types {
        text-align: left;
        position: relative;

        a.add-link-btn {
            position: absolute;
            right: 0px;
            cursor: pointer;
            color: #0099ba;


            svg {
                position: relative;
                top: -3px;
                font-size: 18px;
            }
        }

        a.add-link-btn:hover {
            text-decoration: underline;
        }
    }

    span.nav-link {
        color: #58595b;
        /*margin-right: 20px;
        padding: 5px 15px;*/
        font-weight: bold;
        cursor: pointer;
        display: inline;
    }

    span.nav-link.active {
        border-bottom: 4px solid #66c2d6;
    }

    span.nav-link:hover {
        border-bottom: 4px solid #ccebf1;
    }

    .serch-results-container {
        margin: 20px 0 60px 0;

        img.expertsimg {
            width: 80px;
            height: 80px;
            border-radius: 100px;
        }

        .result-experts {
            margin-top: -6%;
            color: #58595b !important;
            text-align: left;
            padding-left: 85px;
        }

        .result {
            color: #58595B;
            padding-bottom: 20px;
            padding-top: 20px;
            border-bottom: 1px solid #d6dae6;

            .result-description {
                font-family: Arial, Helvetica, sans-serif;
                font-size: 14px;
                letter-spacing: 0.4px;
            }

            header {
                display: flex;
                margin-bottom: 10px;
                font-weight: bold;
            }

            .title {
                white-space: nowrap;
                display: inline-block;
                flex: 2;
                font-family: ShellMedium, sans-serif;
                font-weight: normal;

                .forum-name {
                    font-weight: normal;
                    font-family: Arial, Helvetica, sans-serif;
                    margin-left: 5px;
                    font-size: 12px;
                }

                span {
                    font-weight: normal;
                }
            }

            footer {
                margin-top: 10px;
                font-family: Arial, Helvetica, sans-serif;
                font-size: 14px;
                letter-spacing: 0.4px;

                .benefits-div {
                    margin-top: 5px;
                }

                .benefits {
                    font-weight: bold;
                    padding-right: 20px;
                }

                .data {

                    b {
                        font-weight: 600;
                        font-family: Arial, Helvetica, sans-serif;
                    }

                    span {
                        color: #adaeb1;
                    }
                }


                .statistics {
                    font-weight: bold;
                    margin-top: 10px;
                    color: #808285;

                    span {
                        font-weight: normal;
                        display: inline-block;
                        margin-left: 2px;
                        margin-right: 20px;
                    }
                }
            }
        }

        .help-text {
            font-family: Arial, Helvetica, sans-serif;
            font-size: 14px;
            font-weight: 400;
            top: 3px;
            letter-spacing: -0.5px;
            color: #808285;
        }

        span.data {
            margin-right: 30px;
        }

        .type-a, .type-d {
            .help-text {
                color: #00824a;
                position: relative;
                text-align: right;
                flex: 1;
            }
        }

        .type-b {
            .help-text {
                position: relative;
                text-align: right;
                flex: 1;
            }
        }

        .type-c {
            .help-text {
                color: #00824a;
                position: relative;
                text-align: right;
                flex: 1;
            }

            .statistics {
                font-weight: normal !important;
            }
        }

        .type-d {
            .statistics {
                display: flex;

                > div {
                    flex: 1;

                    > div {
                        font-weight: normal;
                    }
                }
            }
        }

        .type-e {
            .statistics {
                font-weight: normal !important;
            }
        }

        .type-f {
            display: flex;

            img {
                width: 60px;
                border-radius: 50px;
            }

            .leader-title {
                font-weight: bold;
                position: relative;
                top: 4px;
                left: 20px;
            }

            .leader-designation {
                font-weight: normal;
            }
        }
    }

    .serch-results-container .result:last-of-type {
        border: none;
    }

    .type-b, .type-d, .type-a {
        .title {
            cursor: pointer;

            &:hover {
                color: #0099ba;
            }
        }
    }

    .loading-skeleton {
        padding-top: 20px;

        > span {
            padding-bottom: 10px;
            display: block;
        }
    }

    .loading-skeleton-left {
        padding-top: 20px;
        display: block;
    }

    .search-filters {
        label {
            margin-top: 10px;
            font-family: ShellBook, sans-serif;
        }

        margin-bottom: -10px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 14px;
    }

    .custom-filters {
        border-top: 1px solid #ccc;
        margin-top: 20px;
        display: flex;
        border-bottom: 1px solid #ccc;
    }

    .custom-filters.learning-bottom-filters {
        > div.hr {
            border-bottom: 1px solid #ccc;
            padding-bottom: 20px;
        }
    }

    .hr {
        margin-right: 30px;
        display: flex;
    }

    .checkbox-filter-wrapper {
        display: flex;

        input {
            height: auto;
        }

        .form-check {
            position: relative;
            top: 14px;
            width: 0%;
        }
    }

    .custom-filters > div {
        // min-width: 150px;
    }

    .text-left.search-filters.col-md-12 {

        .custom-filters {
            padding: 10px 0;
        }

        label {
            // margin-right: 10px;
        }

        .checkbox-filter-wrapper {
            margin-left: 10px;

            label {
                margin-top: 15px;
            }
        }
    }

    .attachment-link {
        cursor: pointer;
    }

    .top-label {
        // margin-bottom: -10px;
    }

    .hr .top-label {
        // margin-right: 10px;
        font-weight: bold;
        margin-top: 15px;
    }

    .peopleSearchContent:hover {
        transform: scale(1.05);
        border-radius: 6px;
        box-shadow: 2px 1px 4px 2px rgba(0, 0, 0, 0.1);
    }
    /*.peopleContainerDiv efml-people {
        display: flex;
        flex-wrap: wrap;
    }*/
    .peopleSearchContent {
        width: 90%;
        border: 1px solid #ccc;
        margin: 20px 0px 10px 0;
        padding: 30px 10px 0px 15px;
        position: relative;
        box-shadow: 2px 1px 4px 2px rgba(0,0,0,.1);
        min-height: 250px !important;

        img {
            position: absolute;
            width: 90px;
            height: 90px;
            left: 31%;
            border-radius: 100px;
        }

        .peopleUserDisplay {
            margin-top: 110px;
            color: #58595b;
            font-family: ShellMedium, sans-serif;
            font-size: 15px !important;
            text-align: center;
        }

        .peopleUserExpertise {
            font-family: ShellMedium, sans-serif;
            font-size: 13px !important;
            text-align: center;
        }
    }

    .page-item.active .page-link {
        background-color: #0099ba;
        border-color: #0099ba;
        color: #fff;
    }

    .page-link, .page-link:hover {
        color: #0099ba;
    }

    .css-1okebmr-indicatorSeparator {
        width: 0px;
    }

    .result-source-text {
        font-family: Arial, Helvetica, sans-serif;
        font-size: 12px;
    }

    .custom-filters .css-2b097c-container {
        top: 5px;
    }
}
