#banner-component {
    height: 100%;

    .slick-slider, .slick-list, .slick-track, .slick-slide div {
        height: 100% !important;
    }

    .unhide {
        text-align: right;
        color: #0099ba;
        cursor: pointer;
        float: right;
        font-size: 17pt;
        font-family: 'ShellBook', sans-serif;

        &:hover {
            text-decoration: underline;
        }
    }

    .slide-z {
        position: relative;
        background-size: cover;
        background-repeat: no-repeat;
        cursor: pointer;
    }

    .slide-z.no-link {
        cursor: default;
    }

    .slide-z .content-black, .slide-z .content-white {
        position: absolute;
        bottom: 0px;
        height: 35% !important;
        padding: 20px;
        transition: all 500ms;
        transition-delay: 0.2s;
        width: 100%;
    }

    .slide-z:hover {

        .content-black, .content-white {
            height: 90% !important;
            transition: all 500ms;
        }
    }

    .slide-z:hover {

        .slide-title {
            margin-bottom: 10px !important;
            transition: all 1s;
        }
    }

    .slide-noimagetitle {
        width: 93%;
        color: #404040;
        margin-bottom: auto;
        padding-top: 9%;
        padding-left: 11%;
        text-align: left;
        font-family: shellBold, sans-serif;
        font-size: 2.33rem;
    }

    .slide-noimagebody {
        width: 93%;
        padding-left: 11%;
        padding-top: 4%;
        text-align: left;
        color: #404040;
        font-family: shellBook, sans-serif;
        font-size: 1.5rem;
    }

    .slide-noimageauthor {
        width: 93%;
        padding-left: 0%;
        padding-top: 4%;
        text-align: left;
        font-size: 1.33rem;
        font-family: shellMedium, sans-serif;
    }

    .slide-noimage {
        background-color: #e8f5f7;
    }

    .slide-title {
        width: 93%;
        text-align: left;
        color: #404040;
        margin-bottom: 50px;
        transition: all 1s;
        transition-delay: 0.2s;
        // text-transform: uppercase;
        font-family: shellBold, sans-serif;
        font-size: 2.33rem;
        padding-left: 8%;
    }

    .slide-body {
        color: #404040;
        font-family: shellBook, sans-serif;
        font-size: 1.5rem;
        width: 93%;
        text-align: left;
        padding-left: 8%;
        padding-top: 4%;
    }

    .slide-author {
        width: 93%;
        padding-left: 0%;
        padding-top: 4%;
        text-align: left;
        font-size: 1.33rem;
        font-family: shellMedium, sans-serif;
    }

    .slick-dots {
        bottom: 5px;
    }

    .slick-dots li.slick-active button:before {
        opacity: .75;
        color: #fff;
        border: 1px solid #0099ba;
        border-radius: 11px;
        line-height: 16px;
        height: 16px;
        width: 16px;
        font-size: 14px;
        top: -1px;
    }

    .slick-dots li button:before {
        line-height: 16px;
        font-size: 12px;
    }

    #edit.active {
        opacity: 1;
    }

    #edit {
        position: absolute;
        width: 100%;
        justify-content: space-between;
        z-index: 1;
        font-size: 20px;
        //display: flex;
        background-color: #fff;
        opacity: 0;
        transition: opacity .25s ease-in-out;
        -moz-transition: opacity .25s ease-in-out;
        -webkit-transition: opacity .25s ease-in-out;

        .dropdown-toggle::after {
            display: none;
        }

        svg {
            font-size: 26px;
            color: #e1631c;
        }

        .btn-primary {
            border: none;
            background-color: transparent;
            position: relative;
            left: 10px;
        }

        .btn:focus, .btn:active:focus, .btn.active:focus {
            outline: none;
            box-shadow: none;
        }

        .banner-title {
            font-weight: bold;
            padding-left: 10px;
            padding-top: 2px;
        }

        .dropdown {
            //left: 673px;
            position: unset !important;
            float: right;
        }
    }

    .loader-icon {
        height: 100%;
    }

    .loader-shimmer {
        margin-top: -14%;
        width: 80%;
        margin-left: 5%;
    }

    .loader-shimmer-2 {
        width: 50%;
        margin-top: 1.5%;
    }

    .content-black {
        background-color: rgba(0, 0, 0, 0.6);

        .slide-title, .slide-body {
            color: #fff;
        }
    }

    .content-white {
        background-color: rgba(255, 255, 255, 0.9);

        .slide-title, .slide-body {
            color: #58595b;
        }
    }

    .slick-prev {
        left: 15px !important;
        z-index: 1;
    }

    .slick-next {
        right: 15px !important;
    }

    .slick-next:before, .slick-prev:before {
        color: #0099ba;
    }

    .right-custom, .left-custom {
        position: absolute;
        right: 15px;
        z-index: 1;
        top: 45%;
        // background-color: #56afca;
        background-color: #ccc;
        color: #fff;
        cursor: pointer;
        transition: all 500ms;
    }

    .left-custom {
        left: 15px !important;
        right: inherit;
    }

    .right-custom:hover, .left-custom:hover {
        background-color: #656565;
        color: #fff;
    }
}
