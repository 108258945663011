#knowledge-repository {

    .filter-alert {
        text-align: left;
    }

    padding: 40px;

    a {
        color: #0099ba;
        cursor: pointer;
    }

    .edit-mode {
        padding-left: 20px;
    }

    padding-top: 20px;

    .container:first-child {
        text-align: right;
    }

    .kr-header {
        background-color: #e4f5f6;
        display: flex;
        padding: 10px;
        padding-right: 140px;
        text-align: left;
        padding-left: 70px;
        margin-top: 10px;
        margin-bottom: 15px;
        position: relative;

        > div {
            flex-grow: 1;
            position: relative;
            width: 20%;

            > span {
                cursor: pointer;
            }

            .form-group {
                margin-bottom: 0rem !important;
            }

            .filter-container {
                position: absolute !important;
                top: 34px;
                left: 0px;
                border: 1px solid #ccc;
                border-radius: 5px;
                width: 250px;
                // font-family: Arial Regular !important;
                text-align: right;
                padding: 10px;
                overflow: scroll;
                max-height: 210px;
                background-color: #fff;
                z-index: -1;
                opacity: 0;

                span {
                    color: #44a7bd;
                    cursor: pointer;
                    font-weight: bold;
                    align-items: center;
                    font-size: 14px;
                }

                form {
                    text-align: left;
                }
            }

            .filter-container-show {
                opacity: 1;
                z-index: 25;
            }
        }
    }

    .kr-body {


        .kr-panel-container {
            border: 1px solid #fff;
            border-bottom: 1px solid #ece7e7;
            border-radius: 0px;
        }

        .kr-panel-container:hover {
            transform: scale(1.05);
            box-shadow: 2px 1px 4px 2px rgba(0, 0, 0, 0.1);
            //background-color: #f1f1f1;
            cursor: pointer;
        }

        .kr-panel-container.open {
            margin-bottom: 20px;
        }

        .kr-panel-container:hover, .kr-panel-container.open {
            border: 1px solid #ccc;
            border-radius: 10px;
            position: relative;
            top: -2px;
            background-color: #fff;
            z-index: 23;
            width: 101%;
            margin: auto;
            left: -5px;
        }

        .kr-panel-header {
            display: flex;
            padding: 20px;
            cursor: pointer;
            text-align: left;
            padding-left: 70px;

            .doc_file_icon {
                margin: 0px 18px 0 -60px;
            }

            .docFileName {
                display: inline-block !important;
                word-break: break-word !important;
                word-wrap: break-word !important;
                color: #0099ba !important;
            }

            .docFileName:hover {
                text-decoration: underline;
                color: #0099ba !important;
            }

            div {
                flex-grow: 2;
                width: 20%;
                display: flex;
                padding-right: 20px;
            }

            div.icons {
                width: 10% !important;

                .download svg {
                    color: #0078d7;
                }

                .arrow svg {
                    color: rgb(109, 109, 109);
                }
            }
        }

        .kr-panel-body {
            .pteDiv {
                display: flex;
            }

            .created-by, .approved-on, .valid-till, .pte, .add-expertise-area, .approved-by {
                font-family: Arial, Helvetica, sans-serif !important;
            }

            .add-expertise-areaDiv {
                display: flex;
                margin-top: 10px;
            }

            .pte, .add-expertise-area {
                width: 38%;
                color: #9e9e9e;
            }

            .pteVal, .add-expertise-area-val {
                color: #646464;
                width: 60%;
            }

            div.kr-panel-inner {
                display: flex;
                padding-left: 70px;
                // padding-top: 20px;
                padding-bottom: 20px;
                // background-color: #f4f4f4;
                // border-top: 1px solid #f4f4f4;
                div.left, div.right {
                    flex-grow: 1;
                    text-align: left;
                    max-width: 45%;

                    div {
                        font-family: ShellMedium, sans-serif;
                        font-size: 14px;

                        span:first-child {
                            color: #9e9e9e;
                            display: inline-block;
                            margin-top: 10px;
                        }

                        span:last-child {
                            color: #646464;
                        }
                    }
                }

                div.left div span:first-child {
                    width: 28%;
                }

                div.right div span:first-child {
                    width: 38%;
                }
            }
        }
    }

    .pagination {
        margin-top: 9%;
        margin-bottom: 9%;
    }

    .apply-filter {
        position: absolute !important;
        right: -10%;
        color: #3b8ea0;
        text-decoration: underline;
        cursor: pointer;
    }

    .filter-container {
        font-family: ShellBook, sans-serif;

        .title {
            font-weight: bold;
            margin-top: 10px;
        }
    }

    .no-mentors {
        text-align: center;
    }
}
