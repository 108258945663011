

    .banner-review  {
        height: 220px;
        border:1px solid #dee2e6;
        text-align: left;
        position: relative;
        width: 100%;
        border-radius: 3px;
    }

    .banner-review > div {
        position: absolute;
        width: 100%;
        bottom: 40px;
        background-color: rgba(0, 0, 0, 0.6);
        color: #fff;
        padding: 10px;
        height: 160px;
    }

    .banner-review-title {
        font-size: 1rem;
        font-weight: bold;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    span.author {
        font-size: 0.8rem;
    }
    
    .previously-used-banners {
        width: 100%;
        display: flex;
        flex: auto;
        margin-bottom: 20px;
    }

    .previously-used-banners .banner-review {
        height: 180px;
    }

    .previously-used-banners > div {
        width: 33%;
    }

    .previously-used-banners > div .btn {
        margin-bottom: 0px;
        width: 100%;
        margin-top: 5px;
    }

    .banner-review > span {
        display: block;
        text-align: center;
        font-weight: bold;
        position: absolute;
        width: 100%;
        bottom: 0px;
        height: 40px;
        background-color: #ccc;
        padding-top: 8px;
        cursor: pointer;
    }

    .previously-used-banners > div:nth-child(1) {
        margin-right: 5px;
    }

    .previously-used-banners > div:nth-child(3) {
        margin-left: 5px;
    }

    .modal-backdrop:nth-of-type(2) {
        z-index: 1050;
    }

    /* First element */
    body > .modal-backdrop:first-child{
        z-index: 1400;
    }

    /* Rest of the elements */
    body > .modal-backdrop{
        z-index: 1050;
    }

    .edit-banner-form {
        padding-bottom: 20px;
        position: relative;
    }

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    font-family: ShellMedium, sans-serif;
    color: #495057;
}

    .nav-tabs .nav-link.active {
        border-color: #808285 #808285 #fff !important;
        border-width: 1px;
        position: relative;
        top:0px;
    }

    .nav-tabs .nav-link {
        color: #404040;
    }

.nav-tabs .nav-link.disabled {
    color: #ddd;
    font-family: ShellMedium, sans-serif;
}

    .tab-content {
        border: 1px solid #878e97;
        border-top: none;
        padding-top: 5px;        
    }


    .nav-tabs {
         border-bottom: 1px solid #808285;
    }

    .btn-light:not(:disabled):not(.disabled).active, .btn-light:not(:disabled):not(.disabled):active, .show>.btn-light.dropdown-toggle {
        color: #212529;
        background-color: #dae0e5;
        border-color: #d3d9df;
        border: 3px solid #127fec;
    }

    .btn-dark:not(:disabled):not(.disabled).active, .btn-dark:not(:disabled):not(.disabled):active, .show>.btn-dark.dropdown-toggle {
        color: #fff;
        background-color: #1d2124;
        border-color: #127fec;
        border: 3px solid #127fec;
    }

    .btn-group-toggle label.btn {
        padding: 20px;
    }

    .previously-used-banners .banner-review-description {
        font-size: 0.9rem;
        height: 40px;
        position: absolute;
        overflow: hidden;
        line-height: 20px;    
    }

    .previously-used-banners .author {
        font-size: 0.6rem;
        position: absolute;
        bottom: 10px;
    }

    .edit-banner-form .banner-review .no-preview {
        background-color: transparent !important;
        color: #58595b;
        text-align: center;
        position: initial;
        margin-top: 90px;
    }

    .btn-remove-banner {
        position: absolute;
        right: 20px;
        bottom: 20px;   
        border-width: 2px;     
    }

    .nav-tabs .nav-link {
        width: 24%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: center;
        border: 1px solid #ddd;
        border-bottom-color: #808285;
    }

    .modal-footer .btn {
        margin-bottom: 20px;
    }

.used-banners-title {
    width: 100%;
    font-weight: bold;
    font-family: ShellMedium, sans-serif;
}

    .previously-used-banners .banner-review > div {
        bottom: 0px;
    }

    .form-group {
        position: relative;
    }

    .lds-ring {
        position: absolute;
        top: -206px;
        left: 530px;
        z-index: 999;
    }
   
    #edit-banner-wrapper input:invalid {
        border-color: #dc3545;
        padding-right: calc(1.5em + .75rem);
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
        background-repeat: no-repeat;
        background-position: right calc(.375em + .1875rem) center;
        background-size: calc(.75em + .375rem) calc(.75em + .375rem);
    }