#kr-edit-modal {


    .step-loader {
        margin-top: 20px;
    }

    .step-indicator {
        display: flex;
        margin-top: 20px;

        .step {
            flex-grow: 1;
            text-align: center;
            border: 1px solid #ccc;
            color: #ccc;
            margin: 0 20px;
            padding: 10px 0px;
            position: relative;

            div.step-desc {
                font-weight: bold;
            }
        }

        .step::before {
            content: " ";
            width: 40px;
            position: absolute;
            left: -41px;
            top: 36px;
            border-bottom: 1px solid #ccc;
        }

        .step:first-of-type::before {
            width: 20px;
            left: -20px;
        }

        .step.completed {
            background-color: #c1ece8;
            color: #58595b;
            border: 1px solid #009bbd;
        }

        .step.active {
            background-color: #009bbd;
            color: #fff;
            border: none;
        }

        .step.active::before {
            border-bottom: 1px solid #009bbd;
        }
    }

    .data-wrapper {
        padding: 20px;
        max-height: 45vh;
        overflow: scroll;

        form {
            // display: flex;
            // flex-wrap: wrap;
            // padding-left: 0;
            div {
                // flex: 0 0 33.333%;
                margin-top: 5px;
            }
        }

        form.form-0 {
            display: flex;
            flex-wrap: wrap;
            padding-left: 0;

            div {
                flex: 0 0 33%;
            }
        }
        // form.form-1, form.form-2, form.form-3 {
        //     column-count: 3;
        // }
    }

    .form-group {
        margin-bottom: 0rem !important;
    }

    div.step-2 form, div.step-3 form {

        .title {
            font-weight: bold;
            margin-bottom: -5px;
        }

        .items {
            /* column-count: 3; */
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 30px;

            div {
                flex: 0 0 33%;
            }
        }
    }

    .modal-footer {
        border-top: none !important;
        /*padding-top: 0px !important;*/
        display: flex;
        justify-content: space-between;
        padding: 0px 30px;
    }

    .flex-end {
        justify-content: flex-end;
    }
}
