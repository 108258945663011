#delete-leaders-section {
    #delete-leader-wrapper {
        width: 100%;
        position: absolute;
        z-index: 1;
        height: 400px !important;
        transition: 0.6s ease-in-out;
        left: 100%;
        background-color: white;
    }

    #delete-leader-wrapper.active {
        left: 0px;
        position: relative;
    }

    .not-active {
        display: none;
        position: relative;
        opacity: 0;
    }

    .delete-icon {
        position: absolute;
        right: 50px;
        font-size: 22px;
        color: #e58271 !important;
        margin-top: 5px;
        cursor: pointer;
    }

    .delete-leader {
        padding: 10px;

        .delete-icon {
            margin-top: -25px;
        }
    }

    .handler-edit {
        padding-top: 3%;
        display: inline-flex;
    }

    .button-cancel-menu {
        width: 22%;
    }

    .delete-section-leaders {
        margin-left: 8px;
        margin-top: 6px;
    }

    div[class^="leader-title-"] {
        padding: 6px;
    }

    #delete-leader-pop-up {
        height: 60%;
        padding: 20px;
    }

    .arrow-back-category {
        margin-right: 15px;
    }

    .modal-title {
        margin-left: 0px;
    }

    .section-header {
        font-family: shellMedium, sans-serif;
        font-size: 1.2rem;
    }
}
