.quick-links-dropdown {
  background: transparent !important;
  border: none !important;
  color: #58595b !important;
  font-weight: bold !important;
}
.quick-links-dropdown:hover {
  border: none;
}

.quick-link-item {
  display: inline-flex;
  max-width: 425px;
}
.dropdown-item {
  color: #58595b !important;
}

.dropdown-item:hover {
  text-decoration: underline !important;
  color: #0099ba !important;
}

.add-link-enabled {
  color: #0099ba !important;
}

.delete-link-icon {
  cursor: pointer;
  color: #e58271;
  margin-bottom: 2px;
  margin-right: 5px;
}
.btn-primary.focus,
.btn-primary:focus {
  box-shadow: none !important;
}

#add-quick-link {
  .error-new {
    float: left;
    color: #dc3545;
    font-size: 0.9em;
  }
  .form-label {
    margin-bottom: 3px;
  }
  .delete-link-title {
    font-weight: bold;
    margin: 6px;
  }
  .modal-title {
    margin-left: 2px !important;
  }
  .linktitle-validate {
    color: #dc3545;
    margin-top: 25px !important;
  }
}
