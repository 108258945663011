.ms-subtleEmphasis{
    visibility:hidden !important;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}


@font-face { 
  font-family: ShellBook; 
  src: url('../src/assets/fonts/ShellBook.otf'), url('../src/assets/fonts/ShellBook.woff') format('woff'); 
  font-weight: normal;
  font-style: normal;
}

@font-face { 
  font-family: ShellBold; 
  src: url('../src/assets/fonts/ShellBold.otf'), url('../src/assets/fonts/ShellBold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face { 
  font-family: ShellBook; 
  src: url('../src/assets/fonts/ShellBookItalic.otf'), url('../src/assets/fonts/ShellBookItalic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face { 
  font-family: ShellBookMedium; 
  src: url('../src/assets/fonts/ShellMedium.otf'), url('../src/assets/fonts/ShellMedium.woff'); 
  font-weight: normal;
  font-style: normal;
}


@font-face { 
  font-family: ShellMedium; 
  src: url('../src/assets/fonts/ShellMedium.otf'), url('../src/assets/fonts/ShellMedium.woff'); 
  font-weight: normal;
  font-style: normal;
}

@font-face { 
  font-family: ShellLight; 
  src: url('../src/assets/fonts/ShellLight.otf'), url('../src/assets/fonts/ShellLight.woff'); 
  font-weight: normal;
  font-style: normal;
}



body {
  margin: 0;
  font-family: ShellBook, Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.toast-message {
  position: fixed;
  top: 20px;
  right: 0;
  left:0px;
  margin:auto;
  width:380px;
  z-index:1;
}

.toast-message-browser
{
  position: fixed;
    top: 20px;
    right: 0;
    left: 0px;
    margin: auto;
    width: 400px !important;
    z-index: 1;
}
.toast-message-browser.toast {
  max-width: 400px !important;
}

.toast-message-browser
{
  position: fixed;
    top: 20px;
    right: 0;
    left: 0px;
    margin: auto;
    width: 400px !important;
    z-index: 1;
}
.toast-message-browser.toast {
  max-width: 400px !important;
}

.toast-icon {
  font-size: 20px;
  position: relative;
  top:-2px;
}

.btn-primary {
  color: #fff;
  background-color: #0099ba;
  border-color: #0099ba;
}

.btn-primary:hover, .btn-outline-primary:hover {
  background-color: #22798c;
  border-color: #164e5a;
}

.btn-outline-primary {
  color: #0099ba;
  border-color: #0099ba;
}

.btn-outline-secondary {
  border-width: 2px;
}

.btn-outline-secondary:hover {
  background-color: #808285;
  border-width: 2px;
}

.btn-outline-danger {
  color: #e58271;
  border-color: #e58271;
}

.btn-outline-danger:hover {
  background-color: #e58271;
  border-color: #e58271;
}

.edit-dots.active {
  opacity: 1;
}

.modal-content {
  border:1px solid #808285;
}


.edit-dots {
  
  

  position: absolute;
  width:100%;
  text-align: right;
  z-index: 1;
  font-size: 20px;
  background-color: #fff;
  opacity: 0;
  transition: opacity .25s ease-in-out;
  -moz-transition: opacity .25s ease-in-out;
  -webkit-transition: opacity .25s ease-in-out;

  .dropdown-toggle::after {
      display: none;
  }

  svg {
      font-size: 26px;
      color:#e1631c;
  }

  a {
    color: #0078d7;

    svg {
      color: #0078d7;
    }
  }

  .btn-primary {
      border: none;
      background-color: transparent !important;
      position: relative;
      left: 10px;
  }

  .btn:focus, .btn:active:focus, .btn.active:focus{
      outline:none;
      box-shadow:none;
  }

  .banner-title {
      font-weight: bold;
      padding-left: 10px;
      padding-top: 2px;
  }
}

.btn-secondary:hover, .btn-outline-secondary:hover {
  color: #000;
  background-color: #ddd;
  border-color: #ddd;
}

@keyframes custom-animation  {
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: 1200px 0;
  }
}

.react-loading-skeleton {
  animation-name: custom-animation !important;
}

.blue-background-class {
  background-color: #e6f2ff;
}

.blue-background-class {
  background-color: #e6f2ff;
}

.dropdown-item {
  color: #0078d7;
}

.dropdown-item.active, .dropdown-item:active,  .dropdown-item.active svg {
  color: #fff !important;
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #ccc;
  border: 1px solid #ccc;
  background-color: #fff;
}

.react-draggable {
  cursor: move
}

.zoom-out {
  zoom: 0.3;
  -moz-transform: scale(0.3);
  -moz-transform-origin: 0 0;
}