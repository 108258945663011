#all-links-page {
    padding: 40px;


    #linkspage {

        #mainNav {
            list-style-type: none;
            text-align: initial;
            padding-left: 0px;
        }

        #mainNav ul {
            margin-bottom: 5px;
        }

        .mainCategories {
            // padding-right: 76px;
            // float: left;
            // margin-left: 21px;
            // margin-right: -5px;
            // font-family: 'ShellBook';
            font-size: 24pt;
            // width: 1089px;
            color: #404040;
            text-align: left;
            margin-left: 15px;
        }

        .MainLinkCategorytitles {
            display: inline-block;
            margin: 10px 0px -10px 0px;
            padding: 8px 8px 8px 8px;
            transition: all 100ms;
        }

        .MainLinkCategorytitles a {
            font-family: ShellBook, sans-serif;
            color: #0099ba;
            font-size: 13pt;
        }

        .horizontalgrayline {
            margin-left: 406px;
            margin-right: 290px;
            border-bottom: 3px solid #dddddd;
            margin-bottom: 25px;
            margin-top: 20px;
            position: sticky;
            z-index: 2;
            background-color: #fff;
            top: 146px;
        }

        .MainLinkCategory-title {
            text-align: left;
            color: #0099ba;
            font-size: 24pt;
            margin-top: 20px;
            margin-bottom: 15px;
            // font-family: 'ShellLight';
            font-weight: 600;
            position: relative;
            display: inline-block;
        }

        .Mainlinkcontentgrayline {
            padding-bottom: 0px;
            border-bottom: 1px solid #dddddd;
            // float: left;
            // width: 1198px;
            // margin-left: 406px;
            margin-top: 0px;
            min-height: 200px;
        }

        .MainLinkCategory-dec {
            text-align: left;
            color: #404040;
            font-size: 12pt !important;
            margin-bottom: 20px;
            font-family: ShellBook, sans-serif !important;
            line-height: 24pt;
            width: 85%;
        }

        .Sublinkcontent {
            margin-bottom: 20px;
        }

        .subLinkCategory-title {
            text-align: left;
            font-family: ShellBold, sans-serif;
            font-size: 14pt;
            color: #404040;
            margin-bottom: 10px;
        }

        .Links-title {
            text-align: left;
            margin-top: -5px;
            // width: 1210px;
        }

        .Links-title a {
            display: block;
            color: #0078d7;
            font-family: ShellBook, sans-serif !important;
            font-size: 14pt;
            margin-bottom: 15px;
        }

        .Links-desc {
            text-align: left;
            margin-top: -17px;
            width: 80%;
            font-family: ShellBook, sans-serif;
            font-size: 12pt;
            margin-bottom: 16px;
        }

        .Links-desc img {
            height: 400px;
            width: auto;
            max-height: 400px;
            max-width: auto;
        }

        .Links-desc p, .Links-desc span, .Links-desc font, .Links-desc strong {
            text-align: initial;
            color: #404040;
            font-size: 12pt !important;
            margin-bottom: 20px;
            font-family: ShellBook, sans-serif !important;
            line-height: 18pt;
        }



        .mainlinkTitleDesc {
            //padding-bottom: 2px;
            text-align: left;
        }

        .clear {
            clear: both;
        }

        li[class*="active"], li[class*="active"] a {
            background-color: #0099ba;
            color: #ffffff;
            border-radius: 6px;
        }

        .mainlinknav {
            // border-bottom: 50px;
            // padding-bottom: 30px;
            // min-height: 100px;
            height: auto;
            width: 90%;
            position: relative;
        }

        .emptydivforspace {
            height: 50px;
        }

        .edit-dots {
            width: 0px;
            display: inline-block;
            position: absolute;
            right: 15px;
            top: 1px;
        }

        .edit-dots svg {
            color: #e1631c;
        }

        .addlinkicon {
            float: right;
            color: #0078d7;
            margin-top: -41px;
            margin-right: 8px;
        }

        .addlinkicon svg {
            height: 30px;
            width: 30px;
        }

        .editlinkicon, .deletelinkicon {
            margin-top: -8px;
            color: #0078d7 !important;
        }

        .addlinkoptionul {
            list-style-type: none;
            padding-top: 110px;
            margin-left: 405px;
            margin-right: 296px;
        }

        .addlink-linkspage {
            width: 100%;
            margin-bottom: -15px;
        }

        .dropdown-item {
            color: #0078d7 !important;
        }

        .dropdown-menu.show {
            box-shadow: 0px 0px 5px 2px;
            color: #dddd;
        }

        .show > .btn-primary.dropdown-toggle:focus {
            box-shadow: none !important;
        }

        a.dropdown-item {
            font-size: 13pt;
            font-family: shellMedium, sans-serif;
            padding-bottom: 14px;
            padding-top: 10px;
        }

        .grayline-mainlink {
            border-bottom: 3px solid #dddddd;
        }

        .linkspage-mainlinkssection {
            position: sticky;
            top: 0;
            background-color: #fff;
            z-index: 2;
        }


        .add-link-btn {
            font-size: 16px;
            position: absolute;
            right: -100px;
            top: 50%;
            cursor: pointer;
            color: #0099ba;
            text-decoration: underline;

            svg {
                height: 30px;
                width: 30px;
                position: relative;
                top: -3px;
            }
        }

        .linkspage-linkssection {
            padding-bottom: 600px;
        }

        .main-category-shimmer {
            margin-bottom: 10px;
            padding-top: 10px;
        }

        .main-category-desc-shimmer {
            margin-bottom: 10px;
        }

        .sub-category-shimmer {
            margin-bottom: 10px;
        }

        .link-title-shimmer {
            margin-bottom: 10px;
        }

        .no-links {
            text-align: left;
            margin-bottom: 10px;
        }
    }
}
