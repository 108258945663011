#custom-experts {
    .expert-name-cell {
        display: inline-flex;

        .expert-name {
            position: relative;
            top: 2px;
            color: #0099ba !important;
            margin-left: 2%;
        }

        .expert-img {
            height: auto;
            width: 50px;
            border: 1px solid #DDDDDD;
            border-radius: 208px;
        }

        .expert-column1 {
            position: absolute;
            top: 2px;
            left: 38px;
        }

        .expert-column1-efml {
            position: absolute;
            top: 2px;
        }

        .expert-name-efml {
            top: 2px;
            color: #0099ba !important;
            margin-left: 2%;
        }

        .editlinktableicon {
            color: #0078d7 !important;
            position: absolute;
            left: -1px;
            top: 6px;
            font-size: 12pt;
            cursor: pointer;
        }

        .deletelinktableicon {
            margin-left: 7px;
            position: absolute;
            left: 10px;
            top: 4px;
            color: #e58271 !important;
            font-size: 12pt;
            cursor: pointer;
        }
    }

    .expert-row {
        text-align: left;
        word-break: break-word !important;
        word-wrap: break-word !important;
        overflow-wrap: break-word !important;
    }

    .react-grid-HeaderCell-sortable {
        text-align: left;
        background: url('../../assets/images/Arrow.png') no-repeat right;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .react-grid-HeaderCell-sortable--ascending {
        background: none !important;
    }

    .react-grid-HeaderCell-sortable--descending {
        background: none !important;
    }

    .react-grid-Cell__value {
        margin-top: 5px;
        font-size: 11pt;
        font-family: shellMedium, sans-serif;
        color: #58595b;
        white-space: unset !important;
    }

    .react-grid-HeaderCell {
        background-color: #ebebeb;
        white-space: normal !important;
    }

    .react-grid-Cell:not(.editing) .react-grid-Cell__value {
        white-space: unset !important;
    }

    .react-grid-HeaderRow {
        font-size: 11pt;
        font-family: shellbold, sans-serif;
        color: #58595b;
    }

    .tools {
        .btn {
            border: 1px solid #0099ba !important;
            margin-bottom: 5px;
            font-family: 'ShellMedium', sans-serif;
        }
    }

    .dropup, .dropdown {
        position: relative;
    }

    .dropup .caret, .navbar-fixed-bottom .dropdown .caret {
        border-top: 0;
        border-bottom: 4px dashed;
        border-bottom: 4px solid \9;
        content: "";
    }

    .edit-section {
        width: 100%;
        justify-content: space-between;
        z-index: 1;
        font-size: 20px;
        display: flex;
        background-color: #fff;
        transition: opacity 0.25s ease-in-out;
        -moz-transition: opacity 0.25s ease-in-out;
        -webkit-transition: opacity 0.25s ease-in-out;
        margin-top: 2%;

        .dropdown-toggle::after {
            display: none;
        }

        .addlinkicon,
        .editlinkicon {
            color: #0078d7 !important;
        }

        a.dropdown-item {
            font-size: 13pt;
            font-family: shellMedium, sans-serif;
            padding: 10px;
        }

        .dropdown-item {
            color: #0078d7 !important;
        }

        svg {
            font-size: 22px;
            color: #e1631c;
        }

        .btn-primary {
            border: none;
            background-color: transparent;
        }

        .btn:focus,
        .btn:active:focus,
        .btn.active:focus {
            outline: none;
            box-shadow: none;
        }

        .dropdown {
            left: 90%;
        }
    }

    td .table-icon {
        display: flex;
    }

    .table-icon svg {
        margin: 0px 4px 0px 4px;
    }

    .td-editicon {
        color: #0078d7 !important;
        font-size: 15pt;
        cursor: pointer;
        padding: 3px 0px 0px 0px;
    }

    .td-deleteicon {
        color: #e58271 !important;
        font-size: 15pt;
        cursor: pointer;
    }

    .table td,
    .table th {
        vertical-align: baseline;
        text-align: start;
    }

    thead.table-header {
        font-size: 11pt;
        font-family: ShellBold, sans-serif;
        color: #58595b;
        background-color: #ebebeb;
    }

    tbody.table-tbody {
        font-size: 11pt;
        font-family: shellMedium, sans-serif;
        color: #58595b;
    }

    .table {
        width: 95%;
    }

    a.td-a-link {
        color: #0099ba;
    }

    .no-data-available {
        width: 95%;
        position: absolute;
        font-family: shellMedium, sans-serif;
        padding: 10px;
    }

    .export-excel {
        float: right;
        padding: 5px 15px 15px 0px;
    }
}

#delete-expert-click-modal {
    .expert-delete {
        margin-left: 5%;
    }

    .expert-delete-txtinfo {
        font-size: 13pt;
        font-family: shellbook, sans-serif;
        color: #58595b;
        margin-bottom: 20px;
    }

    .expert-name {
        font-size: 11pt;
        font-family: shellbook, sans-serif;
        display: flex;
        color: #58595b;
        line-height: 2.5;
    }

    .expert-name-div {
        margin-left: 5%;
    }

    .btn-primary {
        color: #fff;
        background-color: #e58271;
        border-color: #e58271;
    }

    .btn-primary:hover {
        background-color: #e58271;
        border-color: #e58271;
    }

    .expert-name strong {
        font-family: ShellBold, sans-serif;
        min-width: 120px;
        display: inline-block;
    }
}
