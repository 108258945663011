#header {
    border-bottom: 1px solid #ccc;

    .view-link-disabled {
        visibility: hidden;
    }

    .importantlinks-text {
        left: 0px;
        position: absolute;
        margin-left: 15px;
        font-family: 'ShellBold', sans-serif;
        font-size: 18px;
        color: #212529;
        margin-bottom: 15px;
    }

    .edit-mode-link {
        cursor: pointer !important;
    }

    .min-search {
        width: 120px !important;
    }

    .user-info {
        // position: absolute;
        // right: 30px;
        // margin-top: 20px;
        // padding-right: 20px;
        // padding-left: 20px;
        padding-right: 20px;
        margin-left: -30px;


        a {
            margin-right: 18px;
            margin-top: 5px;
            color: #808285;
            cursor: pointer;
        }

        .user-pic {
            // position: absolute;
            // right: 0px;
            // top: -3px;
            position: absolute;
            right: -10px;
            top: 2px;

            button {
                background-color: transparent;
                border: transparent;
                padding: 0px;

                img {
                    width: 30px;
                    height: 30px;
                    border-radius: 30px;
                }
            }
        }
    }

    .dropdown-menu .dropdown-item {
        color: #0078d7;
    }

    .logo-and-title {
        color: #58595b;
        text-decoration: none;

        h1 {
            // background: url('../../assets/images/logo.png') no-repeat left center;
            // font-size: 32px;
            // padding: 10px 74px;
            // cursor: pointer;
            background: url('../../assets/images/logo.png') no-repeat left center;
            font-size: 26px;
            padding: 10px 0px 8px 80px;
            cursor: pointer;
            width: 620px;
            text-align: left;
            font-weight: bold;
        }
    }

    .search-icon-btn {
        position: relative;
        left: -43px;
        background-color: #fff !important;
        border: none;
        height: 32px;

        svg {
            color: #808285;
        }
    }

    .form-inline input {
        margin-right: 0px !important; /* Using improtant to override bootstrap default style */
        border-radius: 30px;
        border-color: #808285;
        border-width: 2px;
        width: 306px;
        font-family: ShellBook, sans-serif;
        transition: all 1s;
    }

    .form-inline button {
        border-radius: 30px;
        background-color: #66c2d6;
        border-color: #66c2d6;
    }

    .navbar {
        margin-bottom: 10px;
        margin-top: 10px;
    }

    .navbar-nav a.nav-link {
        color: #58595b;
        margin-right: 20px;
        padding: 5px 15px;
        font-weight: bold;
        cursor: pointer;
        transition: width .3s;
    }

    .navbar-nav a.nav-link.active::after {
        width: 0%;
    }

    .navbar-nav a.nav-link.active::after {
        content: '';
        display: block;
        width: 100%;
        height: 4px;
        background: #66c2d6;
        transition: width 2s;
        position: relative;
        top: 6px;
    }

    .navbar-nav a.nav-link:hover::after {
        width: 100%;
        //transition: width .3s;
    }

    .navbar-nav a.nav-link:not(.active)::after {
        content: '';
        display: block;
        width: 0;
        height: 4px;
        background: #ccebf1;
        transition: width .3s;
        position: relative;
        top: 6px;
    }

    .navbar-nav a.nav-link:hover::after {
        width: 100%;
        //transition: width .3s;
    }

    .edit-mode a.nav-link {
        // border:1px solid #dddddd;
    }


    .navbar-expand {
        justify-content: space-between;
        // margin-top: 50px;
    }

    .edit-mode a.nav-link.add-menu {
        color: #0099ba;
        border-width: 0px;
        font-size: 12px;
        padding: 10px 0px;
    }

    .toggle-mode {
        margin-left: 11px;
        font-size: 12px;
        font-weight: bold;
        color: #0020ff;
    }

    .search-icon {
        position: relative;
        top: -3px;
    }

    .s {
        width: 100%;
    }


    *,
    :after,
    :before {
        box-sizing: border-box;
    }

    .clearfix:after,
    .clearfix:before {
        content: '';
        display: table;
    }

    .clearfix:after {
        clear: both;
        display: block;
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    a, a:hover, a.active, a:active, a:visited, a:focus {
        text-decoration: none;
    }

    .content {
        margin: 50px 100px 0px 100px;
    }

    .exo-menu {
        width: 100%;
        float: left;
        list-style: none;
        position: relative;
        background: #23364B;
    }

    .exo-menu > li {
        display: inline-block;
        float: left;
    }

    .exo-menu > li > a {
        color: #ccc;
        text-decoration: none;
        text-transform: uppercase;
        border-right: 1px #365670 dotted;
        -webkit-transition: color 0.2s linear, background 0.2s linear;
        -moz-transition: color 0.2s linear, background 0.2s linear;
        -o-transition: color 0.2s linear, background 0.2s linear;
        transition: color 0.2s linear, background 0.2s linear;
    }

    .exo-menu > li > a.active,
    .exo-menu > li > a:hover,
    li.drop-down ul > li > a:hover {
        background: #009FE1;
        color: #fff;
    }

    .exo-menu i {
        float: left;
        font-size: 18px;
        margin-right: 6px;
        line-height: 20px !important;
    }

    li.drop-down,
    .flyout-right,
    .flyout-left {
        position: relative;
    }

    li.drop-down:before {
        content: "\f103";
        color: #fff;
        font-family: FontAwesome, sans-serif;
        font-style: normal;
        display: inline;
        position: absolute;
        right: 6px;
        top: 20px;
        font-size: 14px;
    }

    li.drop-down > ul {
        left: 0px;
        min-width: 230px;
    }

    .drop-down-ul {
        display: none;
    }

    .flyout-right > ul,
    .flyout-left > ul {
        top: 0;
        min-width: 230px;
        display: none;
        border-left: 1px solid #365670;
    }

    li.drop-down > ul > li > a,
    .flyout-right ul > li > a,
    .flyout-left ul > li > a {
        color: #fff;
        display: block;
        padding: 20px 22px;
        text-decoration: none;
        background-color: #365670;
        border-bottom: 1px dotted #547787;
        -webkit-transition: color 0.2s linear, background 0.2s linear;
        -moz-transition: color 0.2s linear, background 0.2s linear;
        -o-transition: color 0.2s linear, background 0.2s linear;
        transition: color 0.2s linear, background 0.2s linear;
    }

    .flyout-right ul > li > a,
    .flyout-left ul > li > a {
        border-bottom: 1px dotted #B8C7BC;
    }
    /*Flyout Mega*/
    .flyout-mega-wrap {
        top: 0;
        right: 0;
        left: 100%;
        width: 100%;
        display: none;
        height: 100%;
        padding: 15px;
        min-width: 742px;
    }

    h4.row.mega-title {
        color: #404040;
        margin-top: 0px;
        padding-left: 15px;
        padding-bottom: 0px;
        font-family: ShellBold, sans-serif;
        border-bottom: none;
        padding-top: 15px;
        background-color: transparent;
        font-size: 10pt;
        font-weight: bold;
        text-align: left;
    }

    .animated.fadeIn.mega-menu {
        overflow-y: scroll;
        max-height: 450px;
        top: 49px;
    }

    .flyout-mega ul > li > a {
        font-size: 90%;
        line-height: 25px;
        color: #fff;
        font-family: inherit;
    }

    .flyout-mega ul > li > a:hover,
    .flyout-mega ul > li > a:active,
    .flyout-mega ul > li > a:focus {
        text-decoration: none;
        background-color: transparent !important;
        color: #ccc !important;
    }
    /*mega menu*/
    .mega-menu {
        left: 0;
        right: 0;
        padding: 15px;
        display: none;
        padding-top: 0;
        min-height: 100%;
        z-index: 99;
        transition: all 500ms;
        box-shadow: 2px 12px 21px -2px rgba(0, 0, 0, 0.4);
    }

    .mega-menu ul li a {
        line-height: 25px;
        font-size: 90%;
        display: block;
        color: #0099ba !important;
    }

    .mega-menu ul li a:hover {
        text-decoration: underline;
    }

    ul.stander li a {
        padding: 3px 0px;
    }

    ul.description li {
        padding-bottom: 12px;
        line-height: 8px;
    }

    ul.description li span {
        color: #ccc;
        font-size: 85%;
    }

    a.view-more {
        border-radius: 1px;
        margin-top: 15px;
        background-color: #009FE1;
        padding: 2px 10px !important;
        line-height: 21px !important;
        display: inline-block !important;
    }

    a.view-more:hover {
        color: #fff;
        background: #0DADEF;
    }

    ul.icon-des li a i {
        color: #fff;
        width: 35px;
        height: 35px;
        border-radius: 50%;
        text-align: center;
        background-color: #009FE1;
        line-height: 35px !important;
    }

    ul.icon-des li {
        width: 100%;
        display: table;
        margin-bottom: 11px;
    }
    /*common*/
    .flyout-right ul > li > a,
    .flyout-left ul > li > a,
    .flyout-mega-wrap,
    .mega-menu {
        background-color: #fff;
        border: 1px solid #ccc;
        margin: 0 20px 0 10px;
    }
    /*hover*/
    .Blog:hover,
    .Images:hover,
    .mega-menu:hover,
    .drop-down-ul:hover,
    li.flyout-left > ul:hover,
    li.flyout-right > ul:hover,
    .flyout-mega-wrap:hover,
    li.flyout-left a:hover + ul,
    li.flyout-right a:hover + ul,
    .blog-drop-down > a:hover + .Blog,
    li.drop-down > a:hover + .drop-down-ul,
    .images-drop-down > a:hover + .Images,
    .mega-drop-down a:hover + .mega-menu,
    li.flyout-mega > a:hover + .flyout-mega-wrap {
        transition: all 500ms;
        display: block;
    }

    .mega-menu-wrap:hover ~ .nav-link {
        border: 1px solid #ddd;
        border-bottom: 4px solid #ccebf1;
        position: relative;
        bottom: -2px;
        z-index: 100;
    }
    /*responsive*/
    @media (min-width:767px) {
        .exo-menu > li > a {
            display: block;
            padding: 20px 22px;
        }

        .mega-menu, .flyout-mega-wrap, .Images, .Blog, .flyout-right > ul,
        .flyout-left > ul, li.drop-down > ul {
            position: absolute;
        }

        .flyout-right > ul {
            left: 100%;
        }

        .flyout-left > ul {
            right: 100%;
        }
    }

    @media (max-width:767px) {

        .exo-menu {
            min-height: 58px;
            background-color: #23364B;
            width: 100%;
        }

        .exo-menu > li > a {
            width: 100%;
            display: none;
        }

        .exo-menu > li {
            width: 100%;
        }

        .display.exo-menu > li > a {
            display: block;
            padding: 20px 22px;
        }

        .mega-menu, .Images, .Blog, .flyout-right > ul,
        .flyout-left > ul, li.drop-down > ul {
            position: relative;
        }
    }

    a.toggle-menu {
        position: absolute;
        right: 0px;
        padding: 20px;
        font-size: 27px;
        background-color: #ccc;
        color: #23364B;
        top: 0px;
    }

    ul:hover a.nav-link:not(.printable) {
        border: 1px solid #ccc;
        z-index: 100;
        border-bottom: none !important;
        height: 43px;
        background: #fff !important;
        position: relative;
        left: -1px;
        top: -1px;
        margin-bottom: -6px;
        margin-right: 18px;
    }

    ul:hover .customlinks_span {
        // border-bottom: 4px solid #ccebf1;
    }

    .mega-menu-wrap .row > div ul {
        text-align: left;
        margin-top: 1%;

        span {
            color: #404040;
            font-size: 11pt;
            font-family: ShellBook, sans-serif;
            cursor: pointer;
        }

        span:hover {
            text-decoration: underline;
        }
    }

    a.more-links-mega {
        font-family: ShellBook, sans-serif !important;
        font-size: 11pt !important;
        color: #0078d7 !important;
    }

    .mega-menu-wrap .row > div ul li {
        margin-top: 1%;
    }

    ul.stander:last-child {
        margin-bottom: 10px;
        padding-top: 1%;
    }
    //edit menu
    #mylinkmenu-component {
        height: 100%;
        margin-top: -26%;
        width: 10%;
        margin-left: 74%;

        #editmylink.active {
            opacity: 1;
        }

        #editmylink {
            position: absolute;
            justify-content: space-between;
            z-index: 1;
            font-size: 20px;
            display: flex;
            background-color: #fff;
            opacity: 0;
            margin-left: -2%;
            width: 0%;
            transition: opacity .25s ease-in-out;
            -moz-transition: opacity .25s ease-in-out;
            -webkit-transition: opacity .25s ease-in-out;

            .dropdown-toggle::after {
                display: none;
            }

            svg {
                font-size: 26px;
                color: black;
                width: 20px;
                height: 37px;
                padding: 0px;
                margin-top: -1px;
                margin-left: 7px;
                background-color: #f2cf9a;
            }

            .btn-primary {
                border: none;
                background-color: transparent;
                position: relative;
                left: 10px;
            }

            .btn:focus, .btn:active:focus, .btn.active:focus {
                outline: none;
                box-shadow: none;
            }

            #dropdown-menu-align-right {
                margin-left: -15px;
                padding: 0px;
                margin-right: -7px;
                margin-top: -2px;
            }

            .dropdown-item {
                color: #58595b;
                font-family: ShellBook, sans-serif;
            }

            .dropdown-item:active {
                background-color: white;
            }
        }
    }

    .ul-active {
        border: 1px solid #f2cf9a;
    }

    .dropdown-item.active, .dropdown-item:active {
        background-color: white;
    }

    .mega-menu-viewlinks {
        margin-left: 850px;
        margin-top: 20px;
        font-family: ShellBook, sans-serif;
        font-size: 11pt;
        color: #0078d7;
        margin-bottom: 10px;

        a {
            color: #0099ba;
        }
    }

    .mega-menu-viewlinks a:hover {
        text-decoration: underline;
    }

    .notification-wrapper {
        margin-left: 20px;
        color: #767676;
        position: relative;
        top: -2px;
    }

    .notification-wrapper svg {
        font-size: 22px;
    }

    .count {
        background-color: red;
        padding: 2px 6px;
        color: #fff;
        position: absolute;
        top: -5px;
        right: -10px;
        border-radius: 20px;
        font-size: 10px;
    }

    .notifications {
        position: absolute;
        border: 1px solid #ccc;
        width: 250px;
        left: -150px;
        background-color: #fff;
        top: 34px;


        .notification-title {
            border-bottom: 1px solid #ccc;

            div {
                font-weight: bold;
                font-size: 12px;
            }

            span {
                font-size: 12px;
                text-align: center;
            }
        }
    }

    .Header-Links-loading {
        display: flex;

        .css-jn8o09 {
            margin: 0px 5px 0px 5px;
        }
    }

    .search-spoa-box {
        position: absolute;
        right: -35px;
    }

    .no-links-message {
        margin-left: 10px;
    }

    .editTopTabsLink {
        cursor: pointer;
        margin: 6px 0px 0px 8px;
        font-size: 13pt;
        color: #0099ba;
    }

    .editTopTabsLink:hover {
        cursor: pointer;
        color: #0099ba;
    }
}


#header.search {
    margin-bottom: -10px;

    .user-info {
        margin-top: 25px !important;
    }

    .navbar-expand {
        margin-top: 0px;
        margin-left: -20px;
    }

    #basic-navbar-nav {
        display: none !important;
    }

    .navbar {
        margin-bottom: -10px !important;
    }

    .hide {
        display: none;

        input#header-search-input, button.search-icon-btn {
            display: none;
        }

        .user-info {
            position: relative;
            top: -5px;
        }

        .user-pic {
            top: -3px;
        }
    }

}

#custom-webpart-alert {
    .modal-header {
        border-bottom: none !important;
    }

    .modal-body {
        text-align: center;
        font-family: ShellMedium, sans-serif;
        font-size: 16pt;
    }
}
